// To handle special characters in path name
function encodePath(path) {
    return path
        .split('/')
        .map((p) => encodeURIComponent(p))
        .join('/');
}

// Returns a list of images (src will not have the exact file name)
export function importFromPath(requireContext) {
    const images = requireContext
        .keys()
        .sort()
        .filter((item) => item.startsWith('./'))
        .map((item) => requireContext(item));
    return images;
}

// Returns a list of {name, src} with the original file name and src
export function importFromPathWithName(requireContext) {
    const images = requireContext
        .keys()
        .sort()
        .filter((item) => item.startsWith('./'))
        .map((item) => {
            return {
                name: item.replace(/^.*[\\/]/, '').split('.')[0],
                src: requireContext(item),
            };
        });
    return images;
}
