import { Modal, Fade } from '@mui/material';
import { Box } from '@mui/system';
import { storyModalTypes } from 'constants/types';
import Coming from './coming';
import Concept from './concept';
import Creation from './creation';
import styles from './storymodals.module.scss';
import leftArrow from 'assets/images/left-color-arrow.png';
import rightArrow from 'assets/images/right-color-arrow.png';
import { useEffect } from 'react';
import { useRef } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // minWidth: 500,
    // width: 730,
    bgcolor: 'background.paper',
    background: 'linear-gradient(108.79deg, #1008372b 0%, #0a081038 100%)',
    borderRadius: '20px',
    p: 4,
    overflowY: 'scroll',

    '&::-webkit-scrollbar': {
        display: 'none',
    },
};

const StoryModal = ({ open, handleClose, modalType, changeModalType }) => {
    const modalRef = useRef();
    useEffect(() => {
        modalRef.current?.scrollIntoView({ block: 'nearest' });
    }, [modalType]);

    return (
        <Modal open={open} onClose={handleClose} style={{ backdropFilter: 'blur(12px)' }}>
            <Fade in={open}>
                <Box sx={style} className={styles.modalMain}>
                    <div className={styles.body} ref={modalRef}>
                        <div className={styles.content}>
                            {modalType === storyModalTypes.CONCEPT && <Concept />}
                            {modalType === storyModalTypes.CREATION && <Creation />}
                            {modalType === storyModalTypes.COMING && <Coming />}
                        </div>
                        <div className={styles.modalChange}>
                            {modalType === storyModalTypes.CONCEPT && (
                                <div className={styles.btnFirst}>
                                    <button
                                        className={styles.btnRight}
                                        onClick={() => changeModalType(1)}
                                    >
                                        Creation
                                        <img src={rightArrow} alt="" />
                                    </button>
                                </div>
                            )}
                            {modalType === storyModalTypes.CREATION && (
                                <div className={styles.creationBtn}>
                                    <button onClick={() => changeModalType(-1)}>
                                        <img src={leftArrow} alt="" />
                                        Concept
                                    </button>
                                    <button onClick={() => changeModalType(1)}>
                                        Coming
                                        <img src={rightArrow} alt="" />
                                    </button>
                                </div>
                            )}
                            {modalType === storyModalTypes.COMING && (
                                <div className={styles.btnLast}>
                                    <button onClick={() => changeModalType(-1)}>
                                        <img src={leftArrow} alt="" />
                                        Creation
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    <button className={styles.closeBtn} onClick={handleClose}>
                        &times;
                    </button>
                </Box>
            </Fade>
        </Modal>
    );
};

export default StoryModal;
