import { importFromPathWithName } from 'helper/multiImport';
import { generaTypes, mushroomTypes } from './types';

export const mushroomImagesMap = [
    {
        genera: generaTypes.AMANITA,
        type: mushroomTypes.V1.SINGLE,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Amanita/1_amanita-muscaria_single',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.AMANITA,
        type: mushroomTypes.V1.PAIR,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Amanita/1_amanita-muscaria_pair',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.AMANITA,
        type: mushroomTypes.V1.GROUP,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Amanita/1_amanita-muscaria_group',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.AMANITA,
        type: mushroomTypes.V2.SINGLE,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Amanita/2_amanita-muscaria_single',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.AMANITA,
        type: mushroomTypes.V2.PAIR,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Amanita/2_amanita-muscaria_pair',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.AMANITA,
        type: mushroomTypes.V2.GROUP,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Amanita/2_amanita-muscaria_group',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },

    ///////////////////////////////////////////////////////////////////////////////
    /*

    {
        genera: generaTypes.AGROCYBE,
        type: mushroomTypes.V1.SINGLE,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Agrocybe/1_agrocybe-praecox_single',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.AGROCYBE,
        type: mushroomTypes.V1.PAIR,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Agrocybe/1_agrocybe-praecox_pair',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.AGROCYBE,
        type: mushroomTypes.V1.GROUP,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Agrocybe/1_agrocybe-praecox_group',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.AGROCYBE,
        type: mushroomTypes.V2.SINGLE,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Agrocybe/2_agrocybe-praecox_single',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.AGROCYBE,
        type: mushroomTypes.V2.PAIR,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Agrocybe/2_agrocybe-praecox_pair',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.AGROCYBE,
        type: mushroomTypes.V2.GROUP,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Agrocybe/2_agrocybe-praecox_group',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    /////////////////////////////////////////////////////////////////

    {
        genera: generaTypes.CONOCYBE,
        type: mushroomTypes.V1.SINGLE,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Conocybe/1_conocybe-tenera_single',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.CONOCYBE,
        type: mushroomTypes.V1.PAIR,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Conocybe/1_conocybe-rugosa_pair',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.CONOCYBE,
        type: mushroomTypes.V1.GROUP,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Conocybe/1_conocybe-filaris_group',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.CONOCYBE,
        type: mushroomTypes.V2.SINGLE,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Conocybe/2_conocybe-tenera_single',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.CONOCYBE,
        type: mushroomTypes.V2.PAIR,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Conocybe/2_conocybe-rugosa_pair',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.CONOCYBE,
        type: mushroomTypes.V2.GROUP,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Conocybe/2_conocybe-filaris_group',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },

    /////////////////////////////////////////////////////////////////

    {
        genera: generaTypes.COPELANDIA,
        type: mushroomTypes.V1.SINGLE,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Copelandia/1_copelandia-cyanescens_single',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.COPELANDIA,
        type: mushroomTypes.V1.PAIR,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Copelandia/1_copelandia-cyanescens_pair',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.COPELANDIA,
        type: mushroomTypes.V1.GROUP,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Copelandia/1_copelandia-cyanescens_group',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.COPELANDIA,
        type: mushroomTypes.V2.SINGLE,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Copelandia/2_copelandia-cyanescens_single',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.COPELANDIA,
        type: mushroomTypes.V2.PAIR,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Copelandia/2_copelandia-cyanescens_pair',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.COPELANDIA,
        type: mushroomTypes.V2.GROUP,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Copelandia/2_copelandia-cyanescens_group',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },

    /////////////////////////////////////////////////////////////////

    {
        genera: generaTypes.GALERINA,
        type: mushroomTypes.V1.SINGLE,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Galerina/1_galerina-marginata_single',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.GALERINA,
        type: mushroomTypes.V1.PAIR,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Galerina/1_galerina-marginata_pair',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.GALERINA,
        type: mushroomTypes.V1.GROUP,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Galerina/1_galerina-marginata_group',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.GALERINA,
        type: mushroomTypes.V2.SINGLE,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Galerina/2_galerina-marginata_single',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.GALERINA,
        type: mushroomTypes.V2.PAIR,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Galerina/2_galerina-marginata_pair',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.GALERINA,
        type: mushroomTypes.V2.GROUP,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Galerina/2_galerina-marginata_group',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },

    /////////////////////////////////////////////////////////////////

    {
        genera: generaTypes.GYMNOPILUS,
        type: mushroomTypes.V1.SINGLE,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Gymnopilus/1_gymnopilus-junonius_single',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.GYMNOPILUS,
        type: mushroomTypes.V1.PAIR,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Gymnopilus/1_gymnopilus-junonius_pair',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.GYMNOPILUS,
        type: mushroomTypes.V1.GROUP,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Gymnopilus/1_gymnopilus-junonius_group',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.GYMNOPILUS,
        type: mushroomTypes.V2.SINGLE,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Gymnopilus/2_gymnopilus-junonius_single',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.GYMNOPILUS,
        type: mushroomTypes.V2.PAIR,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Gymnopilus/2_gymnopilus-junonius_pair',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.GYMNOPILUS,
        type: mushroomTypes.V2.GROUP,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Gymnopilus/2_gymnopilus-junonius_group',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },

    /////////////////////////////////////////////////////////////////

    {
        genera: generaTypes.HYPHOLOMA,
        type: mushroomTypes.V1.SINGLE,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Hypholoma/1_hypholoma-capnoides_single',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.HYPHOLOMA,
        type: mushroomTypes.V1.PAIR,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Hypholoma/1_hypholoma-marginatum_pair',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.HYPHOLOMA,
        type: mushroomTypes.V1.GROUP,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Hypholoma/1_hypholoma-fasciculare_group',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.HYPHOLOMA,
        type: mushroomTypes.V2.SINGLE,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Hypholoma/2_hypholoma-capnoides_single',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.HYPHOLOMA,
        type: mushroomTypes.V2.PAIR,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Hypholoma/2_hypholoma-marginatum_pair',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.HYPHOLOMA,
        type: mushroomTypes.V2.GROUP,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Hypholoma/2_hypholoma-fasciculare_group',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },

    /////////////////////////////////////////////////////////////////

    {
        genera: generaTypes.INOCYBE,
        type: mushroomTypes.V1.SINGLE,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Inocybe/1_inocybe-geophylla_single',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.INOCYBE,
        type: mushroomTypes.V1.PAIR,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Inocybe/1_inocybe-geophylla_pair',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.INOCYBE,
        type: mushroomTypes.V1.GROUP,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Inocybe/1_inocybe-geophylla_group',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.INOCYBE,
        type: mushroomTypes.V2.SINGLE,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Inocybe/2_inocybe-geophylla_single',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.INOCYBE,
        type: mushroomTypes.V2.PAIR,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Inocybe/2_inocybe-geophylla_pair',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.INOCYBE,
        type: mushroomTypes.V2.GROUP,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Inocybe/2_inocybe-geophylla_group',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },

    /////////////////////////////////////////////////////////////////

    {
        genera: generaTypes.MYCENA,
        type: mushroomTypes.V1.SINGLE,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Mycena/1_mycena-galericulata_single',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.MYCENA,
        type: mushroomTypes.V1.PAIR,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Mycena/1_mycena-galericulata_pair',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.MYCENA,
        type: mushroomTypes.V1.GROUP,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Mycena/1_mycena-galericulata_group',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.MYCENA,
        type: mushroomTypes.V2.SINGLE,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Mycena/2_mycena-galericulata_single',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.MYCENA,
        type: mushroomTypes.V2.PAIR,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Mycena/2_mycena-galericulata_pair',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.MYCENA,
        type: mushroomTypes.V2.GROUP,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Mycena/2_mycena-galericulata_group',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },

    /////////////////////////////////////////////////////////////////

    {
        genera: generaTypes.PANAEOLUS,
        type: mushroomTypes.V1.SINGLE,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Panaeolus/1_panaeolus-semiovatus_single',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.PANAEOLUS,
        type: mushroomTypes.V1.PAIR,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Panaeolus/1_panaeolus-semiovatus_pair',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.PANAEOLUS,
        type: mushroomTypes.V1.GROUP,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Panaeolus/1_panaeolus-subbalteatus_group',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.PANAEOLUS,
        type: mushroomTypes.V2.SINGLE,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Panaeolus/2_panaeolus-semiovatus_single',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.PANAEOLUS,
        type: mushroomTypes.V2.PAIR,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Panaeolus/2_panaeolus-semiovatus_pair',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.PANAEOLUS,
        type: mushroomTypes.V2.GROUP,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Panaeolus/2_panaeolus-subbalteatus_group',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },

    /////////////////////////////////////////////////////////////////

    {
        genera: generaTypes.PLUTEUS,
        type: mushroomTypes.V1.SINGLE,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Pluteus/1_pluteus-cervinus_single',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.PLUTEUS,
        type: mushroomTypes.V1.PAIR,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Pluteus/1_pluteus-cervinus_pair',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.PLUTEUS,
        type: mushroomTypes.V1.GROUP,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Pluteus/1_pluteus-cervinus_group',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.PLUTEUS,
        type: mushroomTypes.V2.SINGLE,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Pluteus/2_pluteus-cervinus_single',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.PLUTEUS,
        type: mushroomTypes.V2.PAIR,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Pluteus/2_pluteus-cervinus_pair',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.PLUTEUS,
        type: mushroomTypes.V2.GROUP,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Pluteus/2_pluteus-cervinus_group',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },

    /////////////////////////////////////////////////////////////////

    {
        genera: generaTypes.PSILOCYBE,
        type: mushroomTypes.V1.SINGLE,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Psilocybe/1_psilocybe-mexicana_single',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.PSILOCYBE,
        type: mushroomTypes.V1.PAIR,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Psilocybe/1_psilocybe-subaeruginosa_pair',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.PSILOCYBE,
        type: mushroomTypes.V1.GROUP,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Psilocybe/1_psilocybe-subaeruginosa_group',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.PSILOCYBE,
        type: mushroomTypes.V2.SINGLE,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Psilocybe/2_psilocybe-mexicana_single',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.PSILOCYBE,
        type: mushroomTypes.V2.PAIR,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Psilocybe/2_psilocybe-subaeruginosa_pair',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },
    {
        genera: generaTypes.PSILOCYBE,
        type: mushroomTypes.V2.GROUP,
        images: importFromPathWithName(
            require.context(
                '../assets/images/all_mushroom/Psilocybe/2_psilocybe-subaeruginosa_group',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
    },

    /////////////////////////////////////////////////////////////////
    */
];

export const getRandomImage = () => {
    const rand1 = Math.floor(Math.random() * mushroomImagesMap.length);
    const images = mushroomImagesMap[rand1].images;
    const rand2 = Math.floor(Math.random() * images.length);
    return images[rand2].src;
};
