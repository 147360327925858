import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    CircularProgress,
    Step,
    StepConnector,
    stepConnectorClasses,
    StepLabel,
    Stepper,
    styled,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import BackgroundSelect from 'components/MintSteps/BackgroundSelect';
import GeneratedSelect from 'components/MintSteps/GeneratedSelect';
import MushroomGenera from 'components/MintSteps/MushroomGenera';
import MushroomSelect from 'components/MintSteps/MushroomSelect';
import MushroomType from 'components/MintSteps/MushroomType';
import SplitType from 'components/MintSteps/SplitType';
import { useMultistepForm } from 'helper/useMultiStepForm';
import { useCallback, useEffect, useState } from 'react';
import { useMemo } from 'react';
import { mergeFourMushroom, mergeTwoMushroom, postSingleMushroom } from 'services/api';
import { response } from 'services/test';
import { toast } from 'react-toastify';
import MintFinal from 'components/MintFinal';
import { splitOptionTypes } from 'constants/types';
import { getImageUrl, listS3Objects } from 'components/AWSImage';

const INITIAL_DATA = {
    splitType: '',
    mushroomGenera: '',
    mushroomType: '',
    mushrooms: [],
    backgroundType: '',
    backgroundColor: '',
};

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderImage:
                'linear-gradient(288.97deg, #F23C24 -31.23%, #D72EF2 56.54%, #29F1F1 133.98%) 1',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderImage:
                'linear-gradient(288.97deg, #F23C24 -31.23%, #D72EF2 56.54%, #29F1F1 133.98%) 1',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        // borderColor: '#eaeaf0',
        // borderTopWidth: 3,
        // borderRadius: 2,
        // borderStyle: 'dashed',
        // backgroundImage: 'linear-gradient(to right, white 33%, rgba(255,255,255,0) 0%);',
        // backgroundPosition: 'bottom;',
        // backgroundSize: '3px 10px',
        // backgroundRepeat: 'repeat-x',
    },
}));

function sliceAndFill(arr, n) {
    const slicedArr = arr.slice(0, n);
    return [...slicedArr, ...Array(arr.length - slicedArr.length).fill('')];
}

const EXPIRY_TIME = 10; // minutes
const MAX_REQUESTS = 3;

export default function MintForm({ handleClose }) {
    const [mushroomData, setMushroomData] = useState(INITIAL_DATA);
    const [generatedOptions, setGeneratedOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [prevStepIndex, setPrevStepIndex] = useState(-1);
    const [selectedImage, setSelectedImage] = useState(null);

    const [requestId, setRequestId] = useState(null);
    const [sessionId, setSessionId] = useState(null);
    const [expiryTimeoutId, setExpiryTimeoutId] = useState(null);
    const [totalRequests, setTotalRequests] = useState(0);

    function updateFields(fields, increment = true) {
        setMushroomData((prev) => {
            return { ...prev, ...fields };
        });
        setPrevStepIndex(currentStepIndex);
        if (increment) next();
    }

    const isS3ImageUsed = () => {
        if (
            mushroomData.splitType === splitOptionTypes.TWO_SPLIT ||
            mushroomData.splitType === splitOptionTypes.FOUR_SPLIT
        ) {
            return true;
        }
        return false;
    };
    console.log({ fromS3: isS3ImageUsed() });

    const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next } = useMultistepForm(
        [
            <SplitType key={0} {...mushroomData} updateFields={updateFields} />,
            <MushroomGenera key={1} {...mushroomData} updateFields={updateFields} />,
            <MushroomType key={2} {...mushroomData} updateFields={updateFields} />,
            <MushroomSelect key={3} {...mushroomData} updateFields={updateFields} />,
            <BackgroundSelect key={4} {...mushroomData} updateFields={updateFields} />,
            <GeneratedSelect
                key={5}
                {...mushroomData}
                updateFields={updateFields}
                setSelectedImage={setSelectedImage}
                generatedOptions={generatedOptions}
                back={goBack}
                isS3Image={isS3ImageUsed()}
            />,
            <MintFinal key={6} {...mushroomData} image={selectedImage} />,
        ],
    );

    // Manage session and request id
    useEffect(() => {
        if (currentStepIndex === 3) {
            console.log('generating session id');
            // const timestamp = Date.now().toString();
            updateSessionId(uuidv4());
            setTotalRequests(0);
        } else if (currentStepIndex === 4) {
            console.log('generating request id ', totalRequests);
            setRequestId(uuidv4());
            setTotalRequests((prev) => prev + 1);
        }
    }, [currentStepIndex]);

    const clearSession = () => {
        setGeneratedOptions([]);
        setSessionId('');
    };

    useEffect(() => {
        if (totalRequests > MAX_REQUESTS) {
            toast.error(
                'You have exceeded the max no of requests, please restart from the beginning',
            );
            clearSession();
        }
    }, [totalRequests]);

    // Function to start the expiry check
    const startExpiryCheck = useCallback(() => {
        if (expiryTimeoutId) {
            console.log('Deleted previous session!');
            clearTimeout(expiryTimeoutId);
        }
        setExpiryTimeoutId(
            setTimeout(() => {
                console.log('Session expired!');
                toast.error(
                    "Your session has expired! You won't be able to generate more mushrooms without restarting from the beginning",
                );
                clearSession();
            }, EXPIRY_TIME * 60 * 1000),
        );
    }, [expiryTimeoutId]);

    // Function to update the session ID and start the expiry check
    const updateSessionId = useCallback(
        (newSessionId) => {
            setSessionId(newSessionId);
            startExpiryCheck();
        },
        [startExpiryCheck],
    );
    /////////////////////////////////////////////////////////////////////

    function goBack() {
        back();
    }

    const labels = [
        mushroomData.splitType,
        mushroomData.mushroomGenera,
        mushroomData.mushroomType,
        mushroomData.mushrooms.length + ' shrooms',
        'Background',
        'Selected',
    ];
    const displayLabels = useMemo(() => {
        return sliceAndFill(labels, currentStepIndex);
    }, [currentStepIndex]);

    useEffect(() => {
        console.log({ currentStepIndex, prevStepIndex });
        if (prevStepIndex === 4 && currentStepIndex === 5) {
            const background = `${mushroomData.backgroundType}-${mushroomData.backgroundColor}`;
            setLoading(true);
            console.log('Submit form');
            console.log({ background, images: mushroomData.mushrooms });
            if (!sessionId) {
                toast.error(
                    'Your session has expired, please restart the mint from the beginning!',
                );
                setLoading(false);
                return;
            }

            switch (mushroomData.splitType) {
                case splitOptionTypes.SINGLE:
                    postSingleMushroom({
                        image: mushroomData.mushrooms[0],
                        background: background,
                    })
                        .then((response) => {
                            setLoading(false);
                            if (response.error) {
                                toast.error(`Error while generating: ${response.error}`);
                                console.log('Error', response.error);
                                return;
                            }
                            console.log({ response });
                            setGeneratedOptions([response]);
                        })
                        .catch((reason) => {
                            console.log('Failed ', reason);
                            toast.error(`Error while generating: ${reason}`);
                            setLoading(false);
                        });

                    // For testing
                    // setTimeout(() => {
                    //     setGeneratedOptions([response]);
                    //     setLoading(false);
                    // }, 2000);
                    break;
                case splitOptionTypes.TWO_SPLIT:
                    console.log({
                        request_id: requestId,
                        session_id: sessionId,
                    });

                    mergeTwoMushroom({
                        image1: mushroomData.mushrooms[0],
                        image2: mushroomData.mushrooms[1],
                        // request_id: 'FGU%TVG$TH%7#8^0kjJo5O!7',
                        // session_id: '#8^0kjJo5O!7#8^0kjJo5O!7',
                        request_id: requestId,
                        session_id: sessionId,
                        background: background,
                    })
                        .then((response) => {
                            setLoading(false);
                            if (response.error) {
                                toast.error(`Error while generating: ${response.error}`);
                                console.log('Error', response.error);
                                return;
                            }
                            console.log({ response });
                            // setGeneratedOptions(response);
                            listS3Objects(sessionId).then(async (result) => {
                                console.log('From s3');
                                console.log({ result });
                                const options = [];
                                for (let i = 0; i < result.length; i++) {
                                    const res = await getImageUrl(result[i].Key);
                                    options.push(res);
                                }

                                setGeneratedOptions(options);
                            });
                        })
                        .catch((reason) => {
                            console.log('Failed ', reason);
                            toast.error(`Error while generating: ${reason}`);
                            setLoading(false);
                        });
                    // For testing
                    // setTimeout(() => {
                    //     setGeneratedOptions([response, response]);
                    //     setLoading(false);
                    // }, 2000);
                    break;
                case splitOptionTypes.FOUR_SPLIT:
                    console.log({
                        request_id: requestId,
                        session_id: sessionId,
                    });

                    mergeFourMushroom({
                        image1: mushroomData.mushrooms[0],
                        image2: mushroomData.mushrooms[1],
                        image3: mushroomData.mushrooms[2],
                        image4: mushroomData.mushrooms[3],
                        request_id: requestId,
                        session_id: sessionId,
                        background: background,
                    })
                        .then((response) => {
                            setLoading(false);
                            if (response?.error) {
                                toast.error(`Error while generating: ${response.error}`);
                                console.log('Error', response.error);
                                return;
                            }
                            console.log({ response });

                            // List all the objects for the seesion id and get each image url
                            listS3Objects(sessionId).then(async (result) => {
                                console.log('From s3');
                                console.log({ result });
                                const options = [];
                                for (let i = 0; i < result.length; i++) {
                                    const res = await getImageUrl(result[i].Key);
                                    options.push(res);
                                }
                                setGeneratedOptions(options);
                            });

                            // listS3Objects(sessionId).then((result) =>
                            //     setGeneratedOptions(result.map((el) => el.Key)),
                            // );
                        })
                        .catch((reason) => {
                            console.log('Failed ', reason);
                            toast.error(`Error while generating: ${reason}`);
                            setLoading(false);
                        });

                    // For testing
                    // setTimeout(() => {
                    //     setGeneratedOptions(Array(24).fill(response));
                    //     setLoading(false);
                    // }, 2000);
                    break;
                default:
                    break;
            }
        }
    }, [currentStepIndex]);

    return (
        <div
            style={{
                background: '#ffffff00',
                minHeight: '800px',
                width: '80vw',
                maxWidth: '1260px',
                color: 'white',
                alignItems: 'center',
            }}
        >
            <ArrowBackIcon onClick={isFirstStep ? handleClose : back} />
            {!isLastStep && (
                <>
                    <Stepper
                        activeStep={currentStepIndex}
                        alternativeLabel
                        connector={<QontoConnector />}
                        style={{ width: '70%', margin: 'auto' }}
                    >
                        {displayLabels?.map((label, index) => (
                            <Step
                                key={index}
                                sx={{
                                    '& .MuiStepLabel-root .Mui-completed': {
                                        color: '#D72EF2', // circle color (COMPLETED)
                                    },
                                    '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                                        {
                                            color: 'white', // Just text label (COMPLETED)
                                        },
                                    '& .MuiStepLabel-root .Mui-completed .MuiStepIcon-text': {
                                        fill: 'white', // circle's number (COMPLETED)
                                    },
                                    '& .MuiStepLabel-root .Mui-active': {
                                        color: '#110C30', // circle color (ACTIVE)
                                    },
                                    '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                                        {
                                            color: '#D72EF2', // Just text label (ACTIVE)
                                        },
                                    '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                                        fill: '#D72EF2', // circle's number (ACTIVE)
                                    },
                                }}
                            >
                                <StepLabel>
                                    {' '}
                                    <p style={{ color: 'white' }}>{label} </p>
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </>
            )}

            {loading ? (
                <div
                    style={{
                        width: '100%',
                        height: '50%',
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress />
                </div>
            ) : (
                step
            )}

            {/* <div>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button color="inherit" disabled={isFirstStep} onClick={back} sx={{ mr: 1 }}>
                Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={next} disabled={isLastStep} sx={{ mr: 1 }}>
                Next
                </Button>
                </Box>
            </div> */}
        </div>
    );
}
