import styles from './style.module.scss';
import herovid from '../../assets/animation/animate.m4v';
import herovid2 from '../../assets/animation/animate2.mp4';
import herovid3 from '../../assets/animation/animate3.mp4';

import logo from 'assets/images/fractal-radio-logo.png';
import audio from '../../assets/animation/music.mp3';
import { useRef, useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp, faVolumeMute } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import ReactPlayer from 'react-player';
import { Select, MenuItem, styled, Button, Tooltip } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
// import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { playlistTyes } from 'constants/types';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { CircularProgress } from '@mui/material';

const animations = importAll(require.context('../../assets/animation', false, /\.(mp4|m4v|webp)$/));

function importAll(r) {
    console.log(r, 'r');
    // To handle special characters in path name
    const encodePath = (path) => {
        console.log(path, 'path');
        return path
            .split('/')
            .map((p) => encodeURIComponent(p))
            .join('/');
    };
    const images = [];
    let id = 1;
    r.keys()
        .sort()
        .forEach((item) => {
            console.log(item, 'item');
            const node = encodePath(r(item));

            images.push({ node, id });
            id++;
        });
    let halfwayThrough = Math.floor(images.length / 2);

    let arrayFirstHalf = images.slice(0, halfwayThrough);
    return arrayFirstHalf;
}

const SUBMIT_PALYLIST_URL =
    'https://docs.google.com/forms/d/14dzB5iX3HFDhfqn7HZP5BolKHf_kFtoqEbV8GCBzGcc/viewform?edit_requested=true';
const COLLAB_URL =
    'https://docs.google.com/forms/d/1caLHQ7L_ooxeWD1_ZCsyedweN5UjgUT7ImrXzjuMHTI/viewform?edit_requested=true';

const StyledSelect = styled(Select)`
    .MuiInputBase-input {
        padding: 4px 18px;
        font-family: 'Manrope', san-serif;
        background-color: transparent;
    }
    width: 100%;
`;

const StyledMenuItem = styled(MenuItem)`
    .MuiMenuItem-root {
        padding: 12px 18px;
        border-bottom: 1px solid #333 !important;
    }

    .MuiButtonBase-root {
        padding: 12px 18px;
        border-bottom: 1px solid #333 !important;
    }
`;

const StyledOulinedButton = styled(Button)`
    background: transparent;
    height: 44px;
    width: 44px;
    border: 1px solid #ffffff;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0;
    &:hover {
        background: transparent;
    }
`;

library.add(faVolumeUp, faVolumeMute);
const FractalRadioPage = () => {
    const [url, setUrl] = useState(playlistTyes['Mom, I\'m with the Shaman']);
    const [muted, setMuted] = useState(false);
    const [playing, setPlaying] = useState(true);
    const [volume, setVolume] = useState(1);
    const soundtrack = useRef(null);
    const [fullscreen, setFullscreen] = useState(false);
    const player = useRef(null);
    // const playerScreen = useFullScreenHandle();
    const videoRef = useRef(null);
    const [playbackSpeed, setPlaybackSpeed] = useState(1);
    const [visible, setVisible] = useState(false);
    const [videoIter, setVideoIter] = useState(0);
    const [videoItem, setVideoItem] = useState(animations[0]);
    const [changeVideo, setChangeVideo] = useState(false); 

    useEffect(() => {
        videoRef.current?.load()
    }, [videoItem]);

    const toggleSound = () => {
        setMuted((prev) => !prev);
    };

    // const handleFullscreen = async () => {
    //     await playerScreen.enter();
    // };

    const handlePlaylistChange = (e) => {
        setUrl(e.target.value);
    };

    // const reportChange = useCallback(
    //     (state, handle) => {
    //         console.log(state);
    //         setFullscreen(state);
    //     },
    //     [playerScreen],
    // );

    const setPlayBack = (type) => {
        if (type === 'F') {
            let speed = playbackSpeed + 0.5;
            videoRef.current.playbackRate = speed;
            setPlaybackSpeed(videoRef?.current?.playbackRate);
        } else {
            let speed = playbackSpeed - 0.5;
            videoRef.current.playbackRate = speed;
            setPlaybackSpeed(videoRef?.current?.playbackRate);
        }
    };

    const handleAnimate = (value) => {
        if (value === 'N') {
            let itr = videoIter + 1;
            if (itr > animations.length - 1) {
                itr = 0;
            }
            console.log(itr, 'itr');
            setVideoItem(animations[itr]);
            setVideoIter(itr);
            setChangeVideo(true);
            setTimeout(() => {
                setChangeVideo(false);
            }, 600);
        } else {
            let itr = videoIter - 1;
            if (itr < 0) {
                itr = animations.length - 1;
            }
            setVideoItem(animations[itr]);
            setVideoIter(itr);
            setChangeVideo(true);
            setTimeout(() => {
                setChangeVideo(false);
            }, 600);
        }
    };

    return (
        <div className={styles.videoContainer}>
            <div className={styles.fractalRadioLogo}>
                <Link to="/">
                    <img src={logo} alt="" />
                </Link>
            </div>
            {changeVideo && (
                <div className={styles.loaderBox}>
                    <CircularProgress />
                </div>
            )}
            <video
                ref={videoRef}
                className={styles.video}
                autoPlay
                muted
                loop
                disablePictureInPicture
                controlsList="nodownload"
                preload="auto"
                playsInline
            >
                <source src={videoItem?.node} type="video/mp4" />
            </video>

            {/* <audio id="audiotrack" ref={soundtrack} autoPlay loop preload="auto">
                <source src={audio} type="audio/mp3" />
            </audio> */}
            <div className={styles.soundcloudContainer}>
                <div className={`${styles.radio__wrapper} ${visible && styles.minimize}`}>
                    <div className={styles.playlistSelect}>
                        <StyledSelect
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            variant="standard"
                            value={url}
                            onChange={handlePlaylistChange}
                        >
                            {Object.entries(playlistTyes).map(([key, value], idx) => (
                                <StyledMenuItem key={idx} value={value}>
                                    {key}
                                </StyledMenuItem>
                            ))}
                        </StyledSelect>
                    </div>

                    <ReactPlayer
                        ref={player}
                        url={url}
                        height={fullscreen ? '100vh' : '393px'}
                        width={fullscreen ? '100vw' : '435px'}
                        muted={muted}
                        volume={volume}
                        playing={playing}
                    />
                    <div className={styles.radio__actions}>
                        <a href={COLLAB_URL} target="_blank" rel="noopener noreferrer">
                            <button className={styles.actionBtn}>Collab with us</button>
                        </a>

                        <span className={styles.dot}></span>
                        <a href={SUBMIT_PALYLIST_URL} target="_blank" rel="noopener noreferrer">
                            <button className={styles.actionBtn}>Submit Playlist</button>
                        </a>
                    </div>
                </div>
                <div className={styles.radio__feat}>
                    <div className={styles.feature__group}>
                        <h5 className={styles.feature__label}>Change Animation:</h5>
                        <Tooltip title="Previous" placement="top">
                            <StyledOulinedButton onClick={() => handleAnimate('P')}>
                                P
                            </StyledOulinedButton>
                        </Tooltip>
                        <Tooltip title="Next" placement="top">
                            <StyledOulinedButton onClick={() => handleAnimate('N')}>
                                N
                            </StyledOulinedButton>
                        </Tooltip>
                    </div>
                    <div className={styles.feature__group}>
                        <h5 className={styles.feature__label}>Animation playback speed:</h5>
                        <Tooltip title="Slow" placement="top">
                            <StyledOulinedButton onClick={() => setPlayBack('S')}>
                                S
                            </StyledOulinedButton>
                        </Tooltip>
                        <Tooltip title="Fast" placement="top">
                            <StyledOulinedButton onClick={() => setPlayBack('F')}>
                                F
                            </StyledOulinedButton>
                        </Tooltip>
                    </div>
                    <div className={styles.feature__group}>
                        <Tooltip title={visible ? 'Maximize' : 'Minimize'} placement="top">
                            <StyledOulinedButton onClick={() => setVisible(!visible)}>
                                {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </StyledOulinedButton>
                        </Tooltip>
                    </div>
                    <div className={styles.feature__group}>
                        <Tooltip title={muted ? 'Unmute' : 'Mute'} placement="top">
                            <StyledOulinedButton onClick={toggleSound}>
                                <FontAwesomeIcon
                                    icon={muted ? faVolumeMute : faVolumeUp}
                                    scale={4}
                                />
                            </StyledOulinedButton>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FractalRadioPage;
