import actions from './action';
import mushroomInfoData from 'constants/mushroomTabDetails';

export const initialState = {
    tabContent: mushroomInfoData[0],
    selectedTab: 1,
    mushroomGallery: mushroomInfoData[0].mushroomGallery,
    walletAddress: null,
    tokens: null,
    tezosWallet: null,
    tokenIds: [],
};

export const reducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case actions.SET_TABCONTENT:
            return {
                ...state,
                tabContent: payload.tabContent,
                selectedTab: payload.selectedTab,
                mushroomGallery: payload.mushroomGallery,
            };
        case actions.SET_WALLETADDRESS:
            return {
                ...state,
                walletAddress: payload.iconAddress,
                tokens: payload.tokens,
                tokenIds: payload.tokenIds,
            };
        case actions.SET_TOKENS:
            return {
                ...state,
                tokens: payload,
            };
        case actions.SET_TEZOSWALLET:
            return {
                ...state,
                tezosWallet: payload,
            };
        default: {
            return state;
        }
    }
};
