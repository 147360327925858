import styles from './whitelistInfo.module.scss';
import { Modal, Box, Fade } from '@mui/material';
import wondergameIcon from 'assets/images/wondergameBtnIcon.png';
import gangstabetIcon from 'assets/images/gangstabetBtnIcon.png';
import coolcatsIcon from 'assets/images/coolcatsBtnIcon.png';
import routes from 'constants/routes';
import { useNavigate } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: 708,
    width: 540,
    bgcolor: 'background.paper',
    background: 'linear-gradient(108.79deg, #100837 0%, #0A0810 100%)',
    borderRadius: '20px',
    p: 4,
    overflowY: 'scroll',

    '&::-webkit-scrollbar': {
        display: 'none',
    },
};

const whitelistButtons = [
    {
        icon: wondergameIcon,
        link: routes.WHITELIST_WONDERGAME,
    },
    {
        icon: gangstabetIcon,
        link: routes.WHITELIST_GANGSTABET,
    },
];

const WhitelistInfoModal = ({ open, setOpen }) => {
    const navigate = useNavigate();

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = ({ link }) => {
        handleClose();
        navigate(link);
        window.location.reload();
    };

    return (
        <Modal open={open} onClose={handleClose} style={{ backdropFilter: 'blur(6px)' }}>
            <Fade in={open}>
                <Box sx={style} className={styles.mainModal}>
                    <div className={styles.mainModal__header}>
                        <h3>Whitelist now</h3>
                        <button onClick={handleClose}>&times;</button>
                    </div>
                    <div className={styles.mainModal__body}>
                        <p className={styles.mainModal__text}>
                            Whitelisting for Wondergame and Ganstaverse has begun. To reserve your
                            place, kindly click the link below.
                        </p>
                        <div className={styles.mainModal__buttons}>
                            {whitelistButtons.map((item, idx) => (
                                <button key={idx} onClick={() => handleClick(item)}>
                                    <img src={item.icon} alt="" />
                                </button>
                            ))}
                        </div>
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
};

export default WhitelistInfoModal;
