import React from 'react';
import styles from './generate.module.scss';
import generatedMush from 'assets/images/generatedMush.png';
import { Button, styled, Tooltip } from '@mui/material';
import refreshIcon from 'assets/images/refresh-fill.png';
import btnArrow from 'assets/images/arrow.png';
import { useEffect } from 'react';
import { useState } from 'react';
import { mushroomGenerator } from 'constants/rmushroomGenerator';
import { useNavigate } from 'react-router-dom';
import routes from 'constants/routes';

const GenerateMushButton = styled(Button)`
    border: 1px solid;
    border-image-source: linear-gradient(99.27deg, #29f19d 30.87%, #fbfb5d 69.86%);
    border-image-slice: 2;
    width: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 8px;

    & > svg {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: linear-gradient(99.27deg, #29f19d 30.87%, #fbfb5d 69.86%);
    }

    & > span {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        /* identical to box height, or 157% */

        letter-spacing: 2px;
        text-transform: uppercase;

        /* Color/Trippy-2 */

        background: linear-gradient(99.27deg, #29f19d 30.87%, #fbfb5d 69.86%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
`;

const GenerateMushroom = () => {
    const [generatedMushInfo, setGeneratedMushroomInfo] = useState(
        mushroomGenerator[Math.floor(Math.random() * mushroomGenerator.length)],
    );
    const [spin, setSpin] = useState(false);
    const navigate = useNavigate();

    const generateNewMushroom = () => {
        setSpin(true);
        setTimeout(() => {
            setGeneratedMushroomInfo(
                mushroomGenerator[Math.floor(Math.random() * mushroomGenerator.length)],
            );
            setSpin(false);
        }, 500);
    };

    return (
        <div className={styles.container}>
            <div className={styles.generate__ctx}>
                <h3 className={styles.title}>Generate a mushroom</h3>
                <div className={styles.generate__gridBox}>
                    <div className={styles.grid__item1}>
                        <GenerateMushButton
                            className={`${spin && styles.generate__btn} ${styles.generate_btn_mb}`}
                            onClick={generateNewMushroom}
                        >
                            <span>Generate Mushroom</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                            >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm4.82-4.924A7 7 0 0 0 9.032 5.658l.975 1.755A5 5 0 0 1 17 12h-3l2.82 5.076zm-1.852 1.266l-.975-1.755A5 5 0 0 1 7 12h3L7.18 6.924a7 7 0 0 0 7.788 11.418z" />
                            </svg>
                        </GenerateMushButton>
                        <div className={`${styles.generatedMush} ${styles.generatedMushMb}`}>
                            <div className={styles.brightenBox}></div>
                            <img src={generatedMushInfo['image']} alt="" />
                        </div>
                        <div className={`${styles.generate__info} ${styles.generate__info_ds}`}>
                            <GenerateMushButton
                                className={`${spin && styles.generate__btn} ${
                                    styles.generate_btn_ds
                                }`}
                                onClick={generateNewMushroom}
                            >
                                <span>Generate Mushroom</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm4.82-4.924A7 7 0 0 0 9.032 5.658l.975 1.755A5 5 0 0 1 17 12h-3l2.82 5.076zm-1.852 1.266l-.975-1.755A5 5 0 0 1 7 12h3L7.18 6.924a7 7 0 0 0 7.788 11.418z" />
                                </svg>
                            </GenerateMushButton>
                            <div className={styles.generate__infoCtx}>
                                {Object.keys(generatedMushInfo)
                                    .filter((key, index) => key !== 'image')
                                    .map((key, index) => (
                                        <div key={index} className={styles.generate_infoProperty}>
                                            <span>{key}</span>
                                            <span>
                                                {key === 'BG color' && (
                                                    <span
                                                        style={{
                                                            background: `${generatedMushInfo[key]
                                                                .toLowerCase()
                                                                .replace(/\s/g, '')}`,
                                                        }}
                                                    ></span>
                                                )}
                                                {generatedMushInfo[key]}
                                            </span>
                                        </div>
                                    ))}
                            </div>
                        </div>
                        <Tooltip title={'Coming soon'} sx={{ cursor: 'pointer' }}>
                            <button
                                className={styles.mint__btn}
                                // onClick={() => navigate(routes.MINT_PAGE)}
                            >
                                <span>Mint Now</span>
                                <img src={btnArrow} alt="" />
                            </button>
                        </Tooltip>
                    </div>
                    <div className={styles.grid__item2}>
                        <div className={`${styles.generatedMush} ${styles.generatedMushDs}`}>
                            <div className={styles.brightenBox}></div>
                            <img src={generatedMushInfo['image']} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GenerateMushroom;
