import React, { useContext, useState } from 'react';
import { Modal, Box, Fade } from '@mui/material';
import styles from './whitelist.module.scss';
import tezosIcon from 'assets/images/tezos.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faClose, faCircleCheck } from '@fortawesome/free-solid-svg-icons';

import gangstaBet from 'assets/images/gangstabet.png';
import iconWallet from 'assets/images/iconWallet.png';
import polygon from 'assets/images/polygon.png';
import wondergameIcon from 'assets/images/wondergame.png';
import knights from 'assets/images/knights.png';
import { getWalletAddress } from 'helper/getWalletAddress';
import { StoreContext } from 'store/useStore';
import actions from 'store/action';
import { getShortenAddress } from 'helper/shorterAddress';
import { getGangstabetOwnedNFTs, getKnightsToken, getWondergameNFTs } from 'helper/common';
import checkFill from 'assets/images/checkbox-circle-fill.png';
import { getTezosAddress } from 'helper/tezosWallet';
import { addWhiteListUser, getWhiteListUserByWallet } from 'helper/firebase';
import { whiteListTypes, chainTypes, notificationTypes } from 'constants/types';
import { useEffect } from 'react';
import Notification from 'components/Notification';
import { MetaMaskContext } from 'helper/metamask';

library.add(fab, faTwitter, faClose, faCircleCheck);

const ICONEX_LINK =
    'https://chrome.google.com/webstore/detail/hana-wallet/jfdlamikmbghhapbgfoogdffldioobgl';
const METAMASK_LINK = 'https://metamask.io/';
const GANGSTABET_NFT_LINK = 'https://gangstabet.io';
const WONDERGAME_NFT_LINK = 'https://wonder.game';
const KNIGHTS_NFT_LINK = 'https://opensea.io/collection/polygon-knp';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // minWidth: 500,
    // width: 730,
    maxHeight: 708,
    bgcolor: 'background.paper',
    background: 'linear-gradient(108.79deg, #100837 0%, #0A0810 100%)',
    borderRadius: '20px',
    p: 4,
    overflowY: 'scroll',

    '&::-webkit-scrollbar': {
        display: 'none',
    },
};

const WhitelistModal = ({ open, setOpen, modalType }) => {
    const { state, dispatch } = useContext(StoreContext);
    const { walletAddress: iconAddress, tokens, tezosWallet, tokenIds } = state;
    const { connect, account: metamaskAddress } = useContext(MetaMaskContext);

    const twitterText = `I just reserved @FractalFungi NFTs with ${
        modalType === whiteListTypes.GANGSTABET
            ? '@GangstaVerse'
            : modalType === whiteListTypes.WONDER_GAME
            ? '@wonderdotgame'
            : '@KnightsPeasants'
    } from ${
        modalType === whiteListTypes.GANGSTABET ? '@helloiconworld' : '@0xPolygon'
    }. A cross-chain partnership to advance psychedelic art community in @Tezos. Join at https://fractalfungi.com/${modalType}`;

    const handleClose = () => setOpen(false);

    const handleIconWalletConnect = async () => {
        let iconAddr = await getWalletAddress();
        let { nftIds, nftOwned } = await getGangstabetOwnedNFTs(iconAddr);
        dispatch({
            type: actions.SET_WALLETADDRESS,
            payload: {
                iconAddress: iconAddr,
                tokens: nftOwned,
                tokenIds: nftIds,
            },
        });
    };

    const handleMetamaskConnect = async () => {
        await connect();
    };

    const fetchWonderNFTs = async () => {
        setLoading(true);
        const balance = await getWondergameNFTs(metamaskAddress);
        dispatch({
            type: actions.SET_TOKENS,
            payload: balance,
        });
        setLoading(false);
    };

    const fetchKnightTokens = async () => {
        setLoading(true);
        const balance = await getKnightsToken(metamaskAddress);
        dispatch({
            type: actions.SET_TOKENS,
            payload: balance,
        });
        setLoading(false);
    };

    useEffect(() => {
        if (metamaskAddress) {
            if (modalType === whiteListTypes.WONDER_GAME) {
                fetchWonderNFTs();
            } else if (modalType === whiteListTypes.KNIGHTS_PEASANTS) {
                fetchKnightTokens();
            }
        }
    }, [metamaskAddress]);

    const handleConnect = async () => {
        modalType === whiteListTypes.GANGSTABET
            ? handleIconWalletConnect()
            : handleMetamaskConnect();
    };

    const [loading, setLoading] = useState(false);

    const handleClick = async () => {
        try {
            setLoading(true);
            const user = getUserDataFromModalType();
            const response = await getWhiteListUserByWallet(user.walletAddress, user.whitelistType);
            if (!response.empty) {
                setNotificationMessage('Address is already added!');
                setNotificationDescription(`Your address ${getShortenAddress(
                    user.walletAddress,
                    6,
                )} has already been
            added. We will verify the address and NFTs before whitelisting.`);
                setNotificationType(notificationTypes.ERROR);
                setLoading(false);
                setsnackbarOpen(true);
                handleClose();
                return;
            }

            await addWhiteListUser(user);
            setNotificationMessage('Your wallet has been whitelisted');
            setNotificationDescription(`Your address ${getShortenAddress(
                user.walletAddress,
                6,
            )} has been
            added. We will verify the address and NFTs before whitelisting.`);
            setNotificationType(notificationTypes.SUCCESS);
        } catch (error) {
            setNotificationMessage('Error whitelisting user!');
            console.log({ error });
            setNotificationType(notificationTypes.ERROR);
        } finally {
            setLoading(false);
            setsnackbarOpen(true);
            handleClose();
        }
    };

    const handleTezosWallet = async () => {
        try {
            const addr = await getTezosAddress();
            console.log(addr);
            dispatch({
                type: actions.SET_TEZOSWALLET,
                payload: addr,
            });
        } catch (error) {
            console.log(error);
        }
    };

    const isWalletConnected = () => {
        if (modalType === whiteListTypes.GANGSTABET && iconAddress) return true;
        if (modalType === whiteListTypes.WONDER_GAME && metamaskAddress) return true;
        if (modalType === whiteListTypes.KNIGHTS_PEASANTS && metamaskAddress) return true;

        return false;
    };

    const getUserDataFromModalType = () => {
        const user = {
            walletAddress: '',
            chainType: '',
            whitelistType: '',
            tezosAddress: tezosWallet,
            nftIds: [],
        };
        if (modalType === whiteListTypes.GANGSTABET) {
            user.walletAddress = iconAddress;
            user.chainType = chainTypes.ICON;
            user.whitelistType = whiteListTypes.GANGSTABET;
            user.nftIds = tokenIds;
        } else if (modalType === whiteListTypes.WONDER_GAME) {
            user.walletAddress = metamaskAddress;
            user.chainType = chainTypes.POLYGON;
            user.whitelistType = whiteListTypes.WONDER_GAME;
            user.nftIds = [];
        } else if (modalType === whiteListTypes.KNIGHTS_PEASANTS) {
            user.walletAddress = metamaskAddress;
            user.chainType = chainTypes.POLYGON;
            user.whitelistType = whiteListTypes.KNIGHTS_PEASANTS;
            user.nftIds = [];
        }

        return user;
    };

    const [snackbarOpen, setsnackbarOpen] = React.useState(false);
    const [notificationMessage, setNotificationMessage] = React.useState('');
    const [notificationDescription, setNotificationDescription] = React.useState('');

    const [notificationType, setNotificationType] = React.useState('success');

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setsnackbarOpen(false);
    };

    return (
        <>
            <Notification
                handleSnackbarClose={handleSnackbarClose}
                title={notificationMessage}
                text={notificationDescription}
                type={notificationType}
                snackbarOpen={snackbarOpen}
            ></Notification>
            <Modal open={open} onClose={handleClose} style={{ backdropFilter: 'blur(3px)' }}>
                <Fade in={open}>
                    <Box sx={style} className={styles.modalMain}>
                        <div className={styles.modalHeader}>
                            <h3>
                                Allowlist for{' '}
                                {modalType === whiteListTypes.GANGSTABET
                                    ? 'GangstaVerse'
                                    : modalType === whiteListTypes.WONDER_GAME
                                    ? 'Wondergame'
                                    : modalType === whiteListTypes.KNIGHTS_PEASANTS
                                    ? 'Knights'
                                    : 'Studio Mirai'}{' '}
                            </h3>
                            <button onClick={() => setOpen(false)}>&times;</button>
                        </div>
                        <ol className={styles.instruction}>
                            <li>
                                Connect your{''}
                                <a
                                    href={
                                        modalType === whiteListTypes.GANGSTABET
                                            ? ICONEX_LINK
                                            : METAMASK_LINK
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {modalType === whiteListTypes.GANGSTABET ? 'ICON' : 'Polygon'}
                                </a>
                                wallet which has{' '}
                                {modalType === whiteListTypes.GANGSTABET
                                    ? 'GangstaBet'
                                    : modalType === whiteListTypes.WONDER_GAME
                                    ? 'Wonder Game'
                                    : modalType === whiteListTypes.KNIGHTS_PEASANTS
                                    ? 'Knights'
                                    : ''}{' '}
                                NFT(s).
                            </li>
                            <li>Connect your Tezos wallet.</li>
                            <li>Tweet about us.</li>
                            <li>
                                Follow us on
                                <a
                                    href={'https://twitter.com/fractalfungi'}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Twitter.
                                </a>{' '}
                            </li>
                        </ol>
                        <div className={styles.mainBody}>
                            <div className={styles.bodyContent}>
                                <div className={styles.bodyContent__wrapper}>
                                    <div className={styles.walletInfo}>
                                        <img
                                            src={
                                                modalType === whiteListTypes.GANGSTABET
                                                    ? iconWallet
                                                    : polygon
                                            }
                                            alt=""
                                        />
                                        <span className={styles.walletTitle}>
                                            {modalType === whiteListTypes.GANGSTABET
                                                ? 'ICON'
                                                : 'Polygon'}
                                        </span>
                                        <img
                                            src={
                                                modalType === whiteListTypes.GANGSTABET
                                                    ? gangstaBet
                                                    : modalType === whiteListTypes.WONDER_GAME
                                                    ? wondergameIcon
                                                    : knights
                                            }
                                            style={{ width: '100px', height: 'auto' }}
                                            alt=""
                                        />
                                    </div>
                                    <div className={styles.connect}>
                                        <button
                                            onClick={handleConnect}
                                            className={`${styles.connectBtn} ${
                                                (iconAddress || metamaskAddress) && styles.connected
                                            }`}
                                        >
                                            <img
                                                src={
                                                    modalType === whiteListTypes.GANGSTABET
                                                        ? iconWallet
                                                        : polygon
                                                }
                                                alt=""
                                            />
                                            {iconAddress || metamaskAddress ? (
                                                <span className={styles.walletAddress}>
                                                    {modalType === whiteListTypes.GANGSTABET
                                                        ? getShortenAddress(iconAddress, 6)
                                                        : getShortenAddress(metamaskAddress, 6)}
                                                </span>
                                            ) : (
                                                <span>Connect</span>
                                            )}
                                        </button>
                                    </div>
                                </div>
                                {(iconAddress || metamaskAddress) && tokens > 0 && (
                                    <p className={styles.tokenSuccess}>
                                        You own {tokens}{' '}
                                        {modalType === whiteListTypes.GANGSTABET
                                            ? 'GangstaBet'
                                            : modalType === whiteListTypes.WONDER_GAME
                                            ? 'Wonder Game'
                                            : 'Knights'}{' '}
                                        NFT(s) and are eligible for the allowlist.
                                    </p>
                                )}
                                {(iconAddress || metamaskAddress) && tokens == 0 && (
                                    <p className={styles.tokenFail}>
                                        You don&apos;t own any{' '}
                                        {modalType === whiteListTypes.GANGSTABET
                                            ? 'GangstaBet'
                                            : modalType === whiteListTypes.WONDER_GAME
                                            ? 'Wonder Game'
                                            : 'Knights'}{' '}
                                        NFTs.
                                    </p>
                                )}

                                <p className={styles.bodyText}>
                                    Connect to{' '}
                                    {modalType === whiteListTypes.GANGSTABET ? 'ICON' : 'Metamask'}{' '}
                                    wallet if you have{' '}
                                    <a
                                        href={
                                            modalType === whiteListTypes.GANGSTABET
                                                ? GANGSTABET_NFT_LINK
                                                : modalType === whiteListTypes.WONDER_GAME
                                                ? WONDERGAME_NFT_LINK
                                                : KNIGHTS_NFT_LINK
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {modalType === whiteListTypes.GANGSTABET
                                            ? 'GangstaBet'
                                            : modalType === whiteListTypes.WONDER_GAME
                                            ? 'Wonder Game'
                                            : 'Knights'}{' '}
                                    </a>{' '}
                                    NFT.
                                    {tokens > 0 && <img src={checkFill} alt="" />}
                                </p>
                            </div>
                            <div className={styles.bodyContent}>
                                <div className={styles.bodyContent__wrapper}>
                                    <div className={styles.walletInfo}>
                                        <img src={tezosIcon} alt="" />
                                        <span className={styles.walletTitle}>Tezos Wallet</span>
                                    </div>
                                    <div className={styles.connect}>
                                        <button
                                            className={`${styles.connectBtn} ${
                                                tezosWallet && styles.connected
                                            }`}
                                            onClick={handleTezosWallet}
                                        >
                                            <img src={tezosIcon} alt="" />
                                            {state.tezosWallet ? (
                                                <span className={styles.tezosWallet}>
                                                    {getShortenAddress(state.tezosWallet, 6)}
                                                </span>
                                            ) : (
                                                <span>Connect</span>
                                            )}
                                        </button>
                                    </div>
                                </div>
                                {tezosWallet ? (
                                    <p className={styles.tokenSuccess}>
                                        Connected your wallet. <img src={checkFill} alt="" />
                                    </p>
                                ) : (
                                    <p className={styles.bodyText}>Connect to wallet.</p>
                                )}
                            </div>
                            <div className={styles.bodyContent}>
                                <div className={styles.bodyContent__wrapper}>
                                    <div className={styles.walletInfo}>
                                        <FontAwesomeIcon color="#fff" icon={faTwitter} />
                                        <span className={styles.walletTitle}>
                                            Twitter (Optional)
                                        </span>
                                    </div>
                                    <div className={styles.connect}>
                                        <a
                                            style={{ all: 'unset' }}
                                            href={`http://twitter.com/share?text=${twitterText}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <button className={styles.connectBtn}>
                                                <FontAwesomeIcon color="#fff" icon={faTwitter} />
                                                <span>Tweet</span>
                                            </button>
                                        </a>
                                    </div>
                                </div>
                                <p className={styles.bodyText}>
                                    Follow{' '}
                                    <a
                                        href={'https://twitter.com/fractalfungi'}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {' '}
                                        @fractalfungi{' '}
                                    </a>{' '}
                                    on Twitter.
                                </p>
                            </div>
                        </div>

                        {loading ? (
                            <div className={styles.btnBox}>
                                <button
                                    disabled
                                    className={styles.continueBtn}
                                    onClick={handleClick}
                                >
                                    Processing..
                                </button>
                            </div>
                        ) : (
                            <div className={styles.btnBox}>
                                <button
                                    disabled={!(isWalletConnected() && tokens > 0 && tezosWallet)}
                                    className={styles.continueBtn}
                                    onClick={handleClick}
                                >
                                    Continue
                                </button>
                            </div>
                        )}
                    </Box>
                </Fade>
            </Modal>
        </>
    );
};

export default WhitelistModal;
