import styles from './mushroomTab.module.scss';
import mushTabInfo from 'constants/mushroomTabDetails';
import SingleMushroom from 'components/SingleMushroom';

const MusroomTab = () => {
    return (
        <div className={styles.mushroomTab__grid}>
            {mushTabInfo.map((item, idx) => (
                <SingleMushroom item={item} key={idx} />
            ))}
        </div>
    );
};

export default MusroomTab;
