import React, { useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './mushroomCarousel.module.scss';
import 'swiper/css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { StoreContext } from 'store/useStore';
import GalleryItem from 'components/GalleryItem';
import { useState } from 'react';

library.add(faAngleLeft, faAngleRight);

const BORDER_COLORS = ['#4FC274', '#FBFB5D', '#29F1F1', '#3749EF', '#1F8037', '#D68B85'];

const MushroomCarousel = () => {
    const sliderRef = React.useRef(null);
    const { state } = useContext(StoreContext);
    const { mushroomGallery } = state;

    const [disablePrev, setDisablePrev] = useState(true);
    const [disableNext, setDisableNext] = useState(false);

    return (
        <div className={styles.slider}>
            <div className={`${styles.slider__wrapper} slider__wrapper`}>
                <Swiper
                    slidesPerView={1}
                    ref={sliderRef}
                    className={styles.cards__slider}
                    onSwiper={(swiper) => {
                        swiper.on('slideChange', () => {
                            swiper.activeIndex === swiper.slides.length - 1
                                ? setDisableNext(true)
                                : setDisableNext(false);

                            swiper.activeIndex === 0 ? setDisablePrev(true) : setDisablePrev(false);
                        });
                    }}
                >
                    {mushroomGallery?.map((mushroomItem, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <div className={styles.card__galleryHome}>
                                    <div className={styles.flex__wrap}>
                                        {mushroomItem
                                            .sort(() => Math.random() - 0.5)
                                            ?.map(({ node }, index) => {
                                                const data = {
                                                    id: node.id,
                                                    name: node.name,
                                                    gener: node.gener,
                                                    border: BORDER_COLORS[
                                                        index % BORDER_COLORS.length
                                                    ],
                                                    image: node.image,
                                                };
                                                return <GalleryItem key={data.id} data={data} />;
                                            })}
                                    </div>
                                </div>
                            </SwiperSlide>
                        );
                    })}

                    <button
                        className={
                            disablePrev ? styles.slider__prev__disabled : styles.slider__prev
                        }
                        disabled={disablePrev}
                        onClick={() => sliderRef.current.swiper.slidePrev()}
                    >
                        <FontAwesomeIcon icon={faAngleLeft} />
                    </button>
                    <button
                        className={
                            disableNext ? styles.slider__next__disabled : styles.slider__next
                        }
                        disabled={disableNext}
                        onClick={() => sliderRef.current.swiper.slideNext()}
                    >
                        <FontAwesomeIcon icon={faAngleRight} />
                    </button>
                </Swiper>
            </div>
        </div>
    );
};

export default MushroomCarousel;
