import generatedMush1 from 'assets/images/generatedMushroom/multi_spiral-dark_blue@up1-down2_429_238_1_galerina-marginata_group.png';
import generatedMush2 from 'assets/images/generatedMushroom/multi_spiral-dark_green@up1-down2_211_163_2_pluteus-cervinus_pair.png';
import generatedMush3 from 'assets/images/generatedMushroom/nebula-red@up1-down2_404_267_1_panaeolus-semiovatus_single.png';
import generatedMush4 from 'assets/images/generatedMushroom/nebula-violet@221@270@108@93@1_copelandia-cyanescens_group.png';
import generatedMush5 from 'assets/images/generatedMushroom/nebula-violet@61@300@173@426@2_hypholoma-marginatum_pair.png';
import generatedMush6 from 'assets/images/generatedMushroom/real_root-brown@left1-right2_224_292_2_mycena-galericulata_pair.png';
import generatedMush7 from 'assets/images/generatedMushroom/shooting_star-violet@225@304@420@304@2_mycena-galericulata_single.png';
import generatedMush8 from 'assets/images/generatedMushroom/spiral-sky_blue@up2-down1_239_236_1_gymnopilus-junonius_group.png';
import generatedMush9 from 'assets/images/generatedMushroom/spiral-yellow@up1-down2_274_34_2_hypholoma-capnoides_single.png';
import generatedMush10 from 'assets/images/generatedMushroom/vortex-sky_blue@315@386@335@284@2_psilocybe-subaeruginosa_pair.png';
import generatedMush11 from 'assets/images/generatedMushroom/vortex2-orange@up2-down1_501_468_1_amanita-muscaria_single.png';

export const mushroomGenerator = [
    {
        Split: '2-split',
        Genera: 'Galerina Marginata',
        Version: '1',
        'Mushroom type': 'Group',
        Background: 'Multi Spiral',
        'BG color': 'Dark Blue',
        Fractal: '#429 , #238',
        image: generatedMush1,
    },
    {
        Split: '2-split',
        Genera: 'Pluteus Cervinus',
        Version: '2',
        'Mushroom type': 'Pair',
        Background: 'Multi Spiral',
        'BG color': 'Dark Green',
        Fractal: '#211 , #163',
        image: generatedMush2,
    },
    {
        Split: '2-split',
        Genera: 'Panaeolus Semiovatus',
        Version: '1',
        'Mushroom type': 'Single',
        Background: 'Nebula',
        'BG color': 'Red',
        Fractal: '#404 , #267',
        image: generatedMush3,
    },
    {
        Split: '4-split',
        Genera: 'Hypholoma marginatum',
        Version: '2',
        'Mushroom type': 'Pair',
        Background: 'Nebula',
        'BG color': 'Violet',
        Fractal: '#61 , #300 , #173 , #426',
        image: generatedMush4,
    },
    {
        Split: '4-split',
        Genera: 'Copelandia Cyanescens',
        Version: '1',
        'Mushroom type': 'Group',
        Background: 'Nebula',
        'BG color': 'Violet',
        Fractal: '#221 , #270 , #108 , #93',
        image: generatedMush5,
    },
    {
        Split: '2-split',
        Genera: 'Mycena Galericulata',
        Version: '2',
        'Mushroom type': 'Pair',
        Background: 'Real Root',
        'BG color': 'Brown',
        Fractal: '#274 , #292',
        image: generatedMush6,
    },
    {
        Split: '4-split',
        Genera: 'Mycena Galericulata',
        Version: '2',
        'Mushroom type': 'Single',
        Background: 'Shooting Star',
        'BG color': 'Violet',
        Fractal: '#225 , #304 , #420 , #304',
        image: generatedMush7,
    },
    {
        Split: '2-split',
        Genera: 'Gymnopilus Junonius',
        Version: '1',
        'Mushroom type': 'Group',
        Background: 'Spiral',
        'BG color': 'Sky Blue',
        Fractal: '#239 , #236',
        image: generatedMush8,
    },
    {
        Split: '2-split',
        Genera: 'Hypholoma capnoides',
        Version: '2',
        'Mushroom type': 'Single',
        Background: 'Spiral',
        'BG color': 'Yellow',
        Fractal: '#274 , #34',
        image: generatedMush9,
    },
    {
        Split: '2-split',
        Genera: 'Amanita Muscaria',
        Version: '1',
        'Mushroom type': 'Group',
        Background: 'Vortex',
        'BG color': 'Orange',
        Fractal: '#501 , #468',
        image: generatedMush10,
    },
    {
        Split: '4-split',
        Genera: 'Psilocybe Subaeruginosa',
        Version: '2',
        'Mushroom type': 'Pair',
        Background: 'Vortex',
        'BG color': 'Sky Blue',
        Fractal: '#315 , #386 , #335 , #284',
        image: generatedMush11,
    },
];
