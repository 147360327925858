import { chainConfigs } from 'config/chainConfigs';

export const switchChainInMetamask = async (chainType) => {
    const { name, symbol, explorerUrl, rpcUrls, chainId } = chainConfigs[chainType];

    // Desktop and MetaMask!
    if (window.ethereum && window.ethereum.isMetaMask) {
        // Switch Network
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: chainId }],
            });
            return true;
        } catch (error) {
            // Error Code 4902 means the network we're trying to switch is not available so we have to add it first
            if (error.code === 4902) {
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: chainId,
                                chainName: name,
                                rpcUrls,
                                blockExplorerUrls: [explorerUrl],
                                nativeCurrency: {
                                    name: symbol,
                                    symbol: symbol,
                                    decimals: 18,
                                },
                            },
                        ],
                    });
                    return true;
                } catch (error) {
                    return false;
                }
            } else {
                return false;
            }
        }
    } else {
        return false;
    }
};
