import styles from './storycard.module.scss';
import arrow from 'assets/images/arrow2.png';
import { Fade } from 'react-awesome-reveal';

const StoryCard = ({ title, text, image, onClickHandler, reversed }) => {
    return (
        <Fade direction="up" triggerOnce={true} fraction={0.5}>
            <div className={reversed ? styles.story__containerReversed : styles.story__container}>
                <div className={styles.story__ctx}>
                    <div>
                        <h3 className={styles.story__title}>{title}</h3>
                    </div>
                    <p className={styles.story__text}>{text}</p>
                    <a className={styles.story__btn} onClick={onClickHandler}>
                        <p>Learn more</p>
                        <img src={arrow} alt="" />
                    </a>
                </div>

                <img className={styles.story__img} src={image}></img>
            </div>
        </Fade>
    );
};

export default StoryCard;
