import BigNumber from 'bignumber.js';
import IconService from 'icon-sdk-js';
import wondergameAbi from 'constants/wondergameAbi.json';
import knightsMinAbi from 'constants/knightsMinAbi.json';

const { HttpProvider, IconConverter, IconBuilder } = IconService;
const { CallBuilder } = IconBuilder;

const provider = new HttpProvider('https://ctz.solidwallet.io/api/v3');
const iconService = new IconService(provider);

const GANGSTABET_TOKEN_ADDRESS = process.env['REACT_APP_GANGSTABET_TOKEN'];
const WONDERGAME_CONTRACT_ADDRESS = process.env['REACT_APP_WONDERGAME_TOKEN'];
const KNIGHTS_PEASANTS_CONTRACT_ADDRESS = process.env['REACT_APP_KNIGHTS_TOKEN'];

const convertToBN = (value) => {
    const resultInBigNumber = IconConverter.toBigNumber(value);
    const bigNumberValue = new BigNumber(resultInBigNumber).dividedBy(new BigNumber(10).pow(18));
    return bigNumberValue.toFixed(0);
};

export const getGangstabetToken = async (address) => {
    const callBuilder = new CallBuilder();
    const call = callBuilder
        .to(GANGSTABET_TOKEN_ADDRESS)
        .method('balanceOf')
        .params({
            _owner: address,
        })
        .build();
    const tokenBalance = await iconService.call(call).execute();
    const convertBN = convertToBN(tokenBalance);
    // const convertBN = convertToBN(1000000000000000000);
    return convertBN;
};

export const getGangstabetOwnedNFTs = async (address) => {
    const callBuilder = new CallBuilder();
    const call = callBuilder
        .to(GANGSTABET_TOKEN_ADDRESS)
        .method('get_all_nft_owned')
        .params({
            address,
            page: '1',
        })
        .build();
    const response = await iconService.call(call).execute();
    return { nftIds: response.nft_list, nftOwned: Number(response.nft_owned) };
};

export const getWondergameNFTs = async (address) => {
    const contract = new window.web3.eth.Contract(wondergameAbi, WONDERGAME_CONTRACT_ADDRESS);
    const tokens = await contract.methods.balanceOf(address).call();
    return Number(tokens);
};

export const getKnightsToken = async (address) => {
    const contract = new window.web3.eth.Contract(knightsMinAbi, KNIGHTS_PEASANTS_CONTRACT_ADDRESS);
    const tokens = await contract.methods.balanceOf(address).call();
    return Number(tokens);
};
