import { importFromPath } from 'helper/multiImport';
import { v4 as uuidv4 } from 'uuid';

const tabImages = importFromPath(
    require.context('../assets/images/shroompedia/tab', false, /\.(png|jpe?g|svg)$/),
);

const amanitaNodes = importAll(
    require.context('../assets/images/shroompedia/amanita', false, /\.(png|jpe?g|svg)$/),
    'amanita',
);
const agrocybeNodes = importAll(
    require.context('../assets/images/shroompedia/agrocybe', false, /\.(png|jpe?g|svg)$/),
    'agrocybe',
);
const conocybeNodes = importAll(
    require.context('../assets/images/shroompedia/conocybe', false, /\.(png|jpe?g|svg)$/),
    'conocybe',
);
const copelandiaNodes = importAll(
    require.context('../assets/images/shroompedia/copelandia', false, /\.(png|jpe?g|svg)$/),
    'copelandia',
);
const galerinaNodes = importAll(
    require.context('../assets/images/shroompedia/galerina', false, /\.(png|jpe?g|svg)$/),
    'galerina',
);
const gymnopilusNodes = importAll(
    require.context('../assets/images/shroompedia/gymnopilus', false, /\.(png|jpe?g|svg)$/),
    'gymnopilus',
);
const hypholomaNodes = importAll(
    require.context('../assets/images/shroompedia/hypholoma', false, /\.(png|jpe?g|svg)$/),
    'hypholoma',
);
const inocybeNodes = importAll(
    require.context('../assets/images/shroompedia/inocybe', false, /\.(png|jpe?g|svg)$/),
    'inocybe',
);
const mycenaNodes = importAll(
    require.context('../assets/images/shroompedia/mycena', false, /\.(png|jpe?g|svg)$/),
    'mycena',
);
const panaeolusNodes = importAll(
    require.context('../assets/images/shroompedia/panaeolus', false, /\.(png|jpe?g|svg)$/),
    'panaeolus',
);
const pluteusNodes = importAll(
    require.context('../assets/images/shroompedia/pluteus', false, /\.(png|jpe?g|svg)$/),
    'pluteus',
);
const psilocybeNodes = importAll(
    require.context('../assets/images/shroompedia/psilocybe', false, /\.(png|jpe?g|svg)$/),
    'psilocybe',
);

function importAll(r, gener) {
    // To handle special characters in path name
    const encodePath = (path) => {
        return path
            .split('/')
            .map((p) => encodeURIComponent(p))
            .join('/');
    };

    const images = [];
    r.keys()
        .sort()
        .forEach((item) => {
            if (item.startsWith('./')) {
                const fileName = item.replace('./', '').split('.')[0];

                const split = fileName.split('-');
                const name = split.length > 1 ? split[1] : split[0];
                const type = split.length > 1 ? split[0] : '';

                const node = {
                    gener: getFormattedGener(gener, type),
                    color: '',
                    id: uuidv4(),
                    name,
                    image: encodePath(r(item)),
                };

                images.push({ node });
            }
        });
    images.sort(() => Math.random() - 0.5);
    return images;
}

function getFormattedGener(gener, type) {
    if (type) {
        return `${gener} ~ ${type}`;
    }
    return gener;
}

const PAGE_LENGTH = 6;
export function paginate(nodes, pageLength = PAGE_LENGTH) {
    const paginated = [];
    for (let i = 0; i < nodes.length; i += pageLength) {
        paginated.push(nodes.slice(i, i + pageLength));
    }
    return paginated;
}

const mushTabInfo = [
    {
        id: 1,
        title: 'AMANITA',
        text: "The “Where's Waldo” of fungi, the Amanita Muscaria (also called fly agaric) is an amicable little mushroom with a vibrant red and white polka dotted cap. If you play Super Mario Bros you might be familiar with the genus as a super mushroom, but in reality, it is only a mildly hallucinogenic mushroom; it definitely won't turn you into your super-form. We say mildly hallucinogenic, but the Amanita Muscaria has contributed significantly to humanity since the year 4000BC by filling in for alcohol before its introduction. The ibotenic acid and muscimol content in the fungi intoxicates the body, and it starts behaving as though drunk. But Muscarias are slightly unreasonable, in that while alcohol allows you to consume a significant amount before the poisoning starts, Muscaria charges the exorbitant price of non-fatal poisoning for a comparatively tame high. It doesn't offer much in terms of culinary flexibility like some wines do. In fact, Muscarias do not even have a distinctive odor or taste. The Amanita Muscaria isn't called Waldo just for its distinct cap, but for its introverted-ness as well. It's mostly found in the northern Eurasian region between the months of August and November on a range of hardwood and softwood trees, notably birches. \n\n ",
        image: tabImages[0],
        mushroomGallery: paginate(amanitaNodes),
    },
    {
        id: 2,
        title: 'AGROCYBE',
        text: 'To proudly represent the convex capped fungi, Agrocybe arrives. Like the reliable eldest child, it is known to be found in grass, in woodchips, in the woods, or in urban settings, all around the globe. An anomaly in the magic mushroom fraternity, the Agrocybe is that one cousin your Asian parents are all proud of. This brown spored shroom is one of the most edible species found and harvested commercially in East-Asian and Australian countries.\n\n The agrocybe is certainly not your favourite cousin- rather, its the cousin that outshines you in every way. Apart from having species that are natural food and beverage flavour enhancers in various Asian cuisines, it has a slightly meaty odour, and a nutty taste. However, like any perfect cousin, the agrocybe also has a a hidden drawback. Most field guides say that this group of field cap fungi is best avoided when gathering mushrooms to eat as they are easily confused with a number of seriously toxic toadstools. \n\n',
        image: tabImages[1],
        mushroomGallery: paginate(agrocybeNodes),
    },
    {
        id: 3,
        title: 'CONOCYBE',
        text: 'The Conocybe is a thin, lanky shroom with a lamp shape cap that strangely resembles a dunce cap. Fungi bullying is apparently a thing, as Conocybe comes from the Greek word for “cone heads”. It’s a somewhat common fungi that can get confused with another genus unless supervised under a microscope.Its species occupy grasslands on dead moss, dead grass, sand dunes, decayed wood, and dung. Widespread and unassuming, the Conocybe is actually the perfect example for the saying “don’t judge a book by its cover”. \n\n To counter its unimpressive appearance, the Conocybe holds alarming levels of toxins. So much so that they’re called death traps. On the other hand, some specific species such as Conocybe siligineoides contain hallucinogen compounds such as psilocin and psilocybin. The Conocybe siligineoides was such a potent psychedelic that it was even used for spiritual healing rituals by the Mazatecs priests of Oaxaca. But seeing as how the naked, inexperienced eye cannot distinguished the lethal species from the psychedelic, we recommend staying away from Conocybes. \n\n ',
        image: tabImages[2],
        mushroomGallery: paginate(conocybeNodes),
    },
    {
        id: 4,
        title: 'COPELANDIA',
        text: 'This genus likes to think it’s not like other fungi. It is named after Edwin Bingham Copeland, an American who gathered fungi. And just like a human, Copelandia’s gills change as they grow. They start off mottled gray and develop black splotches later on. As they grow, they develop a bluish tint, that differentiates them from their cousins, the Panaeolus. Copelandia is also always in search of a way to outshine its cousin. Along with the sought-after serotonin, Copelandia also contains Urea. You know, just for a little kick. \n\n For psychoactive spice, Copelandia contains baeocystin apart from the more common psilocybin and psilocin. The taste and smell of the mushroom are actually relatively starchy, and yet it is the most potent hallucinogenic mushroom. This might be due to its chemical composition, or they might just have a really good marketing team. You’d think that cemented its “not like other fungi” status. But in its effort to truly stand out, Copelandia is found most commonly in Equatorial regions and the African Savannah, unlike its brethren who thrive mostly in the north. You’ve got to respect the hustle. \n\n ',
        image: tabImages[3],
        mushroomGallery: paginate(copelandiaNodes),
    },
    {
        id: 5,
        title: 'GALERINA',
        text: 'At this point, you’ll have spotted a trend with these mushroom caps. The Galerina belongs to the flat, slightly curved, portable umbrella group. This cutomised helmet tells us alot about the fungi. In the case of people, a styrofoam helmet might mean they’re a pretentious prick cycling on the highway. In the case of Galerina’s light to rusty brown helmet, it signifies the “death cap”, a terrifying signal that you’ve stumbled upon a highly toxic mushroom. The toxin in Galerina is shared with the Amanita Virosa, or Death Angel. If earlier genus’s hinted at the possibility of poison, the Galerina has established itself as the Godfather of deadly species, as most contain alpha-amanitins. However, the yang to this overwhelming yin is the Galerina Steglichii, a very rare, blue-bruising psychedelic species containing the hallucinogen psilocybin. \n\n Frankly, the Galerina should terrify you. While a zombie invasion is unlikely, death via mushroom poisoning is highly probable. These innocent looking fungi are found in abundance on aesthetic moss-covered logs, in all continents except Antarctica. Anyway, who wants to go mushroom picking? The woods are lovely this time of year! \n\n',
        image: tabImages[4],
        mushroomGallery: paginate(galerinaNodes),
    },
    {
        id: 6,
        title: 'GYMNOPILUS',
        text: 'We’ve all heard of “all that glitters is not gold”. But do all golden things glitter? Apparently not! Gymnopilus, the spectacularly golden mushroom, can be found at the base of rotting tree trunks mostly in England and Ireland. It would be paradoxical to note that something so beautiful is created through rot. However, that is not the only ironical thing about Gymnopilus- for land-dwelling fungi, they are the highest-gilled mushroom species! Gymnopilus too shares Psilocybin with about 200 other fungi. However, unlike both gold and Psilocybe, Gymnopilus never caught on with recreational consumers. \n\n Unfortunately, the mushroom possesses a bitter aftertaste that no reasoning - no matter how religious - would work in its favor. Except, of course, its magical properties. A study from the 70s unearthed an incredible instance of Japanese woodcutters encountering a group of nuns in a lively state of mind. If you think this is strange, just wait until you realise how lively. The poor woodcutters were horrified to find the nuns singing, dancing, and giggling in an open, public space. Some coaxing led to the discovery of a certain Gymnopilus species as the culprit- it was named “the laughing mushroom”. Today they’ve come up with a much more complicated name, but the effects of ingestion are still very similar. \n\n',
        image: tabImages[5],
        mushroomGallery: paginate(gymnopilusNodes),
    },
    {
        id: 7,
        title: 'HYPHOLOMA',
        text: 'While the rest of its fungi friends were being consumed as psychedelics, Hypholoma had created a name for itself in the streets as a gangster. As for its street name, it received the title of “sulphur tuft”. Yes, it is as bad as it sounds. After long years of working as a gangster, Hypholoma was able to distinguish its gang into two major groups. The darkly tinged dark brown shrooms with purple-brown spore-print ones went out into the world and created a good name for the genus. In contrast, the toxic, sulphuric poisoning gang activity was designated to the veil-less, smooth-spored, yellow-tinged species of the genus.\n\n Apart from the difference in physical appearances, the toxic species are horribly bitter in taste. You know, just in case the toxins weren’t enough of a reason to not eat them. While they differ in characteristics, both groups of the gang are found mostly on dead and rotting wood. Typical. It’s not uncharacteristic to find them scattered across moors, moss, and other damp, musty places. Like any gang, Hypholoma also has castaways - a few rare species that contain Psilocybin and can be consumed as a hallucinogen. \n\n',
        image: tabImages[6],
        mushroomGallery: paginate(hypholomaNodes),
    },
    {
        id: 8,
        title: 'INOCYBE',
        text: 'One of the most beautiful parts of getting to know a mushroom genus, is identifying the closest thing these fungi have to a personality. There are traits in these organisms that allude to a collective identity. Inocybe, however, is utterly mystifying in its traits. Coming from the Greek for “fibrous hat”, Incoybe does justice to its name by having a hairy, bell-shaped, conical cap. A cap that later becomes silky convex. It has a nondescript, discoloured yellow tints, and a mostly white shade. \n\n However, some species will also develop a blushing cap, tinged with pink on their newly convex cap and stems. The indecision in physical appearance continues to Inocybe’s gills as well. While the closely spaced gills start off while, they slowly turn grey-brown as spores mature and turn older and pinker, or bruise pink. Now what seems like a so-far gently, unassuming mushroom has an alarming reveal. Most Inocybe specie contain Muscarine, deadly for the human body. However, some species are also hallucinogenic, as they contain psilocybina and pricolin. Good luck figuring out which one’s go in our mouth and which one’s don’t.\n\n  ',
        image: tabImages[7],
        mushroomGallery: paginate(inocybeNodes),
    },
    {
        id: 9,
        title: 'MYCENA',
        text: 'Mycena or Angel’s Bonnet is a slight, lanky stalk topped with a conical tip resembling a glorified bean sprout. Quite unfortunate. Perhaps in an attempt to compensate, they attempt to emulate an intimidating persona by being scattered and inconsistent in their genus trait. An example is how its defining feature, the bonnet-like cap flattens out with age and becomes a shield-like thing. Most of these mushrooms are inedible. However, those who species which play at being a hallucinogen with a hint of psilocybin make life a little easier by conveniently bruising blue, like the rest of its cousins. \n\n Mycena’s features are incoherent and inconsistent- as are its taste and nature. Even its smell- a strong odour of iodine, is only common among its drier specimen. The moister caps, with different splotching patterns, also possess a different smell described as bleachlike, or radishlike. Not very flattering to the genus. A rational explanation for its inconsistency is that since Mycena is not consumed widely as an edible mushroom, it does not have to keep up appearances and maintain a consistent self-image across all its species. After all, if we all could, wouldn’t we juggle between multiple identities all day? You’ll find the various species of Mycena scattered across the board, but mostly they prefer to hang out on broadleaf woodlands after they’ve fallen. \n\n',
        image: tabImages[8],
        mushroomGallery: paginate(mycenaNodes),
    },
    {
        id: 10,
        title: 'PANAEOLUS',
        text: 'The genus Panaeolus has 7 species to its name. A name that, incidentally, does a devastating job at describing it, because Panaeolus is essentially Greek for “splotchy” alluding to its mottled gray gills, black spores in patches on their gills. The brown little bell-shaped mushrooms are found widely in North-American and European grasslands, although like in every genus, Panaeolus has a few edgy species that prefer to grow on damp wood. Despite their diversity, the genus is inedible. You can, however, consume it for its psychedelics. While some psychoactive components remain unidentified, several members of this genus are known to contain psilocin and psilocybin. Don’t let its unimpressive looks fool you though, all members of the splotchy brown genus contain serotonin derivatives, the same chemical that helps your brain take care of your body. Mysterious, average looks, with a surprisingly valuable skill? Give Panaeolus its own rom-com already! \n\n',
        image: tabImages[9],
        mushroomGallery: paginate(panaeolusNodes),
    },
    {
        id: 11,
        title: 'PLUTEUS',
        text: 'In another case of extraordinarily unoriginal naming, we have Pluteus. Its name in Latin literally means a protective fence or screen - which alludes to the genus’s dark edge of the gills. A very fashion-conscious fungi, its cap is convex (that style is all the rage right now, I’ve heard) and it has a dark pink, light brown tint to it- unlike those other fungi with the dull dark coloured splotches. True to its nature so far, some species of Pluteus are inedible- they’re simply eye candy, you see. The one’s you want to consume will bruise blue. It’ll also make you see blue, and a few other shades of the rainbow due to its Psilocybin content. While Pluteus also grows on decaying wood like some of its fungi cousins, they grow almost exclusively on softwoods (timber of coniferous trees). These glamorous group of mushrooms prefer to grow in very small exclusive batches. Because of course not everyone can sit with the cool kids. To further cement its exclusivity, the Pluteus avoids being seen in Britain and Ireland. Rather, they’re found to thrive in parts of northern and central mainland Europe as well as in North America. \n\n',
        image: tabImages[10],
        mushroomGallery: paginate(pluteusNodes),
    },
    {
        id: 12,
        title: 'PSILOCYBE',
        text: 'Unlike the rest of its brethren, Psilocybe has been named after its defining feature; its bald head. A trait which, in hindsight, so many mushrooms seem to share. Interestingly, while science does not yet allow for time travel, Psilocybe might fashion as the closest thing to time machines we’ll ever get. Due to the contents of the hallucinogenic Psilocybin drug in Psilocybe and other hallucinogenic mushrooms. You wouldn’t expect this from a nondescript, little brown shroom, but ingestion of a certain amount of Psilocybe may result in a warped sense of place, time, and the self. This fungus was so potent that they were actively ingested for spiritual purposes by indigenous tribes. While that particular excuse would never fly in today’s society, Native American Shamans continued to reap from its extrasensory perceptual effects for long amounts of time. While we’ve all chuckled at anecdotes of Coca-Cola having cocaine in its original recipe, the amount of “spiritual healing” accomplished by hallucinogenic mushrooms in the past is incredible.Maybe the post-modern return to spirituality isn’t as bad. \n\n',
        image: tabImages[11],
        mushroomGallery: paginate(psilocybeNodes),
    },
];

export default mushTabInfo;
