import Web3 from 'web3';

export const chainConfigs = {
    polygon: {
        name: 'Polygon Mainnet',
        rpcUrls: ['https://polygon-rpc.com/'],
        chainId: Web3.utils.toHex(137),
        symbol: 'MATIC',
        shortName: 'Polygon',
        explorerUrl: 'https://polygonscan.com/',
    },
};
