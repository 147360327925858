import Header from 'components/Header';
import styles from './story.module.scss';
import img1 from 'assets/images/Group1.png';
import img2 from 'assets/images/Group2.png';
import img3 from 'assets/images/Group3.png';

import Footer from 'components/Footer';
import StoryCard from 'components/StoryCard';
import { useState } from 'react';
import { storyModalTypes } from 'constants/types';
import StoryModal from 'components/StoryModal';

import heroImg1 from 'assets/images/mush-bg-left.png';
import heroImg2 from 'assets/images/mush-bg-right.png';

import arrowDownIcon from 'assets/images/arrow-down.png';

const subHeadings = [
    {
        title: 'Concept',
        text: 'The idea of Fractal Fungi was born out of fascination of mathematical art and mushrooms. As the world adopted a new form of digital art and ownership (NFTs), we saw this as a perfect opportunity to create a unique piece of art in the form of fractals and fungi.',
        image: img1,
    },
    {
        title: 'Creation',
        text: 'To support the continued decentralization of the Fractal Fungi project, we introduced Fractal Fungi DAO. All merged fractal fungi NFT holders will participate in the DAO. Each holder can cast a vote to choose which projects will be pursued.',
        image: img2,
        reversed: true,
    },
    {
        title: 'Coming',
        text: 'The idea of Fractal Fungi was born out of fascination of mathematical art and mushrooms. As the world adopted a new form of digital art and ownership (NFTs), we saw this as a perfect opportunity to create a unique piece of art in the form of fractals and fungi.',
        image: img3,
    },
];

const StoryPage = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalType, setModalType] = useState(storyModalTypes.CONCEPT);

    const changeModalType = (increment) => {
        setModalType(modalType + increment);
    };

    return (
        <div className={styles.storyPage}>
            <div className={styles.story__hero}>
                <div className={styles.brightenBox}></div>
                <div className={styles.story__headerBg}>
                    <div className={styles.container}>
                        <Header />
                        <div className={styles.hero}>
                            <img srcSet={heroImg1} className={styles.hero__bgimageLeft} />
                            <div className={styles.hero__ctx}>
                                <h3 className={styles.hero__title}>Story</h3>
                                <p className={styles.hero__text}>
                                    <p>
                                        Fractal Fungi was born out of a fascination with
                                        mathematical art and mushrooms. As the world adopted a new
                                        form of digital art and ownership (NFTs), we saw this as a
                                        perfect opportunity to create a unique piece of art in the
                                        form of fractals and fungi.
                                    </p>
                                </p>
                            </div>
                            <img srcSet={heroImg2} className={styles.hero__bgimageRight} />
                        </div>
                        {/* <div className={styles.mouse}>
                            <div className={styles.mouse__icon}>
                                <span></span>
                            </div>
                            <span className={styles.mouse__text}>Scroll down</span>
                        </div> */}
                    </div>
                </div>
                <div className={styles.arrowDown}>
                    <img srcSet={arrowDownIcon} />
                </div>
            </div>
            {/* <div className={styles.info}>
                <p>
                    Fractal Fungi was born out of a fascination with mathematical art and mushrooms.
                    As the world adopted a new form of digital art and ownership (NFTs), we saw this
                    as a perfect opportunity to create a unique piece of art in the form of fractals
                    and fungi.
                </p>
            </div> */}

            <div className={styles.subHeadingContainer}>
                {subHeadings.map((el, index) => (
                    <StoryCard
                        key={el.title}
                        text={el.text}
                        image={el.image}
                        title={el.title}
                        reversed={el.reversed}
                        onClickHandler={() => {
                            setModalType(index);
                            setModalOpen(true);
                        }}
                    />
                ))}
            </div>

            <StoryModal
                open={modalOpen}
                handleClose={() => setModalOpen(false)}
                modalType={modalType}
                changeModalType={changeModalType}
            />

            <Footer />
        </div>
    );
};

export default StoryPage;
