import axios from 'axios';

const API = axios.create({
    baseURL: 'https://dev-fractal-fungi.herokuapp.com/api',
    headers: {
        token: '123321',
    },
});

export const postSingleMushroom = async (data) => {
    try {
        data = {
            ...data,
            to_telegram: '0',
        };
        const response = await API.post('/interactive_single_mushroom', data);
        if (response.data.error) return response.data;
        return response.data?.status?.single_mushroom_with_background;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const mergeTwoMushroom = async (data) => {
    try {
        data = {
            ...data,
            to_telegram: '0',
            write_to_s3: '1',
        };
        const response = await API.post('/interactive_merge_2_mushroom', data);
        if (response.data.error) return response.data;
        return response.data?.status?.merged_mushroom_all;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const mergeFourMushroom = async (data) => {
    try {
        data = {
            ...data,
            to_telegram: '0',
            write_to_s3: '1',
        };
        const response = await API.post('/interactive_merge_4_mushroom', data);
        if (response.data?.error) return response.data;
        return response.data?.status?.merged_mushroom_all;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const productionSingleMushroom = async (data) => {
    try {
        data = {
            ...data,
        };
        const response = await API.post('/production_single_mushroom', data);
        if (response.data.error) return response.data;
        return response.data?.status?.single_mushroom_with_background;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const productionMergeTwoMushroom = async (data) => {
    try {
        data = {
            ...data,
        };
        const response = await API.post('/production_merge_2_mushroom', data);
        if (response.data.error) return response.data;
        return response.data?.status?.single_mushroom_with_background;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
