import styles from './header.module.scss';
import logo from 'assets/images/Logo.png';
import { Link, useLocation } from 'react-router-dom';
import menu from 'assets/images/Menu.png';
import { useEffect, useState, useContext, useRef } from 'react';
import routes from 'constants/routes';
import { StoreContext } from 'store/useStore';
import actions from 'store/action';
import { getShortenAddress } from 'helper/shorterAddress';
import { getTezosAddress } from 'helper/tezosWallet';
import { Close } from '@mui/icons-material';
import copy from 'assets/images/copy.png'
import arrowDown from 'assets/images/arrow-down-s-line.png';
import { Popover } from '@mui/material';
import { copyToClipboard } from 'helper/copyToClipboard';
import { toast } from 'react-toastify';

const Header = () => {
    const [openMobileNav, setOpenMobileNav] = useState(false);
    const [hideNav, setHideNav] = useState(false);
    const location = useLocation();
    const { state, dispatch } = useContext(StoreContext);
    const navRef = useRef();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        if (location.pathname === routes.MINT_PAGE) setHideNav(true);
    }, []);

    const handleConnect = async () => {
        try {
            if (!state.tezosWallet) {
                const addr = await getTezosAddress();
                console.log(addr);
                dispatch({
                    type: actions.SET_TEZOSWALLET,
                    payload: addr,
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleNavClose = (e) => {
        e.stopPropagation();
        if (!navRef.current.contains(e.target)) {
            setOpenMobileNav(false);
        }
    };

    const handleCopy = () => {
        copyToClipboard(state.tezosWallet);
        toast.info('Copied address to clipboard');
    }

    const handleLogout = () => {
        dispatch({
            type: actions.SET_TEZOSWALLET,
            payload: null
        })
        toast.info('Logged out');
        handleClose();
    }

    return (
        <div className={styles.header}>
            <div className={styles.logo}>
                <Link to="/">
                    <img src={logo} alt="Fractal Fungi" />
                </Link>
            </div>
            <div className={`${styles.navbar}`}>
                <ul className={styles.mintPage_nav}>
                    <div className={styles.mintPage__menu}>
                        {!state.tezosWallet ? (
                            <button onClick={handleConnect} className={styles.connect}>
                             <span>
                                Connect Wallet
                             </span>
                            </button>
                        ): (
                            <>
                                <div className={styles.address__box} onClick={(e) => handleClick(e)}>
                                    <img src={copy} alt="" />
                                    <span>{getShortenAddress(state.tezosWallet, 6)}</span>
                                    <img src={arrowDown} alt="" />
                                </div>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    anchorPosition={{
                                        top: 20,
                                        left: 0,
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    sx={{ marginTop: '10px', '.MuiPopover-paper': { borderRadius: 0 } }}
                                >
                                    <div className={styles.profileDrop}>
                                        <div className={styles.profileAction} onClick={handleCopy}>
                                            Copy Address
                                        </div>
                                        <div className={styles.hrLine}></div>
                                        <div className={styles.profileAction} onClick={handleLogout}>
                                            Logout
                                        </div>
                                    </div>
                                </Popover>
                            </>
                        )}
                       
                    </div>
                    <div className={styles.mintPage__menu}>
                        <button className={styles.menuBtn} onClick={() => setOpenMobileNav(true)}>
                            <img src={menu} alt="" />
                        </button>
                    </div>
                </ul>
            </div>

            <div
                className={`${styles.mobileNavContainer} ${openMobileNav && styles.open}`}
                onClick={handleNavClose}
            >
                <button className={styles.close} onClick={handleNavClose}>
                    <Close />
                </button>
                <div className={styles.mobileNav} ref={navRef}>
                    <div className={styles.logo}>
                        <Link to="/">
                            <img src={logo} alt="Fractal Fungi" />
                        </Link>
                    </div>
                    <ul className={styles.navbar__menu}>
                        <li className={styles.navbar__list}>
                            <Link to={'/'}>Home</Link>
                        </li>
                        <li className={styles.navbar__list}>
                            <Link to={'/dao'}>FractalDAO</Link>
                        </li>
                        <li className={styles.navbar__list}>
                            <Link to={'/story'}>Story</Link>
                        </li>
                        <li className={styles.navbar__list}>
                            <Link to={'/fractalFungiRadio'}>Fractal Radio</Link>
                        </li>
                        {/* <li className={styles.navbar__list}>
                            <Link>Gallery</Link>
                        </li>
                        <li className={styles.navbar__list}>
                            <Link>Tokenomics</Link>
                        </li>
                        <li className={styles.navbar__list}>
                            <Link>Roadmap</Link>
                        </li> */}
                        <li className={styles.navbar__list}>
                            <Link to={'/shroompedia'}>Shroompedia</Link>
                        </li>
                        <div className={styles.copyright}>
                            <p>Copyright © 2023 • Fractal Fungi</p>
                        </div>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Header;
