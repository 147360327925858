import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { injected } from './walletInjector';
import { toast } from 'react-toastify';
import { switchChainInMetamask } from './switchChain';
import { chainTypes } from 'constants/types';
import { chainConfigs } from 'config/chainConfigs';
import Web3 from 'web3';

export const MetaMaskContext = React.createContext({});

export const MetaMaskProvider = ({ children }) => {
    const [isActive, setIsActive] = useState(false);
    const [isLoading] = useState(true);
    const { activate, account, deactivate } = useWeb3React();

    const isMetaMaskInstalled = () => {
        return typeof window?.ethereum !== 'undefined';
    };

    const connect = async () => {
        if (isMetaMaskInstalled()) {
            try {
                window.web3 = new Web3(window.ethereum);
                if (window.ethereum.networkVersion !== chainConfigs[chainTypes.POLYGON].chainId) {
                    const isSwitched = await switchChainInMetamask(chainTypes.POLYGON);
                    if (!isSwitched) throw Error('Error while switching the network');
                }

                await activate(injected, async (error) => {
                    console.log('error in metamask connect ', error.name);
                    switch (error.name) {
                        case 'UnsupportedChainIdError':
                            toast.error('Please change to Polygon mainnet.');

                            break;

                        case 'UserRejectedRequestError':
                            toast.error('Please allow to connect on metamask.');

                            break;

                        default:
                            if (error?.code === -32002) {
                                toast.error(
                                    'A request for connecting to metamask is processing. Please finish it first.',
                                );
                                break;
                            }

                            toast.error('Unable to connect to your wallet');

                            break;
                    }
                });
            } catch (error) {
                toast.error('Error on connecting.');
            }
        } else {
            toast.error(
                <span>
                    Please Download and Install Metamask Extension from this{' '}
                    <a
                        href="https://metamask.io/download/"
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: 'underline', color: '#29F1F1' }}
                    >
                        Link
                    </a>
                </span>,
            );
        }
    };

    const disconnect = () => {
        console.log('Deactivating...');

        try {
            deactivate();
        } catch (error) {
            console.log('Error on disconnecting: ', error);
        }
    };

    const handleIsActive = useCallback(() => {
        setIsActive(Boolean(account));
    }, [account]);

    useEffect(() => {
        handleIsActive();
    }, [handleIsActive]);

    const values = useMemo(
        () => ({
            isActive,
            account,
            isLoading,
            connect,
            disconnect,
        }),
        [isActive, isLoading, connect, account],
    );

    return <MetaMaskContext.Provider value={values}>{children}</MetaMaskContext.Provider>;
};

export function useMetaMask() {
    const context = React.useContext(MetaMaskContext);

    if (context === undefined) {
        throw new Error('useMetaMask hook must be used with a MetaMaskProvider component');
    }

    return context;
}
