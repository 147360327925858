import { mushroomTypes } from 'constants/types';
import { importFromPath } from 'helper/multiImport';
import styles from './mushroomType.module.scss';
const typeImages = importFromPath(
    require.context('../../../assets/images/mint/type', false, /\.(png|jpe?g|svg)$/),
);

const Option = ({ title, image, selected, onSelect }) => {
    return (
        <div
            className={selected ? styles.option__selected : styles.option}
            onClick={() => onSelect(title)}
        >
            <img src={image} />
            <h3>{title}</h3>
        </div>
    );
};

export default function MushroomType({ mushroomType, mushroomGenera, updateFields }) {
    const filteredImages = typeImages.filter((el) => el.includes(mushroomGenera.toLowerCase()));

    //TODO: Group into folders or arrange properly
    // Sort order - group, pair, single (just reverse)
    const typeOptions = [
        { title: mushroomTypes.V1.SINGLE, image: filteredImages[2] },
        { title: mushroomTypes.V1.PAIR, image: filteredImages[1] },
        { title: mushroomTypes.V1.GROUP, image: filteredImages[0] },

        { title: mushroomTypes.V2.SINGLE, image: filteredImages[5] },
        { title: mushroomTypes.V2.PAIR, image: filteredImages[4] },
        { title: mushroomTypes.V2.GROUP, image: filteredImages[3] },
    ];
    const onSelect = (value) => {
        updateFields({ mushroomType: value });
    };

    return (
        <div className={styles.container}>
            <div className={styles.description}>
                <h4>You have selected {mushroomGenera}</h4>
                <p>
                    Vitae rhoncus nisi nulla et tristique tempus sagittis aliquet mauris. Pulvinar
                    lobortis porta gravida viverra dolor tincidunt enim non.{' '}
                </p>
            </div>
            <h3 className={styles.title}>Select type</h3>

            <div className={styles.optionsContainer}>
                {typeOptions.map((option, i) => (
                    <Option
                        title={option.title}
                        description={option.description}
                        image={option.image}
                        key={i}
                        selected={mushroomType === option.title}
                        onSelect={onSelect}
                    />
                ))}
            </div>
        </div>
    );
}
