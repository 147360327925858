import Header from 'components/Header';
import styles from './style.module.scss';
import heroImg1 from 'assets/images/mush-bg-left.png';
import heroImg2 from 'assets/images/mush-bg-right.png';

import collection1 from 'assets/images/collection1.png';
// import collection2 from 'assets/images/collection2.png';
// import collection3 from 'assets/images/collection3.png';
// import collection4 from 'assets/images/collection4.png';
// import collection5 from 'assets/images/collection5.png';
// import collection6 from 'assets/images/collection6.png';
import collection7 from 'assets/images/collection7.jpeg';

import Footer from 'components/Footer';
import { Grid } from '@mui/material';
import CollectionCard from 'components/CollectionCard';

const collections = [
    {
        image: collection1,
        title: 'Tell Ur Bull-Dog to Fly',
        creator: 'Toxic Motel',
        url: 'https://objkt.com/asset/KT1HwseRGVwcmqJeRpXdsvuPyuAKGPMMbSGo/7',
    },
    {
        image: collection7,
        title: 'Tree Spirit',
        creator: 'NuwanShilpa',
        url: 'https://objkt.com/asset/KT1G9PbTxH4pxfPTsTKdsLbhDxDFQ2MzKZNX/0',
    },
];

const FractalDAO = () => {
    return (
        <div className={styles.FractalDAO}>
            <div className={styles.brightenBox}></div>
            <div className={styles.dao__headerBg}>
                <Header />
            </div>
            <div className={styles.dao__hero}>
                <div className={styles.container}>
                    <div className={styles.hero}>
                        <img srcSet={heroImg1} className={styles.hero__bgimageLeft} />
                        <div className={styles.hero__ctx}>
                            <h3 className={styles.hero__title}>FractalDAO</h3>
                            <p className={styles.hero__text}>
                                FractalDAO is an on-chain governance experiment to build a vibrant
                                psychedelic art community in Tezos. The DAO will collect treasury
                                from the secondary sales of all the NFTs (and a part of the primary
                                sales). The Fused Fungi NFTs can create proposals and vote on
                                proposals to distribute the funds directly from the DAO.
                            </p>
                        </div>
                        <img srcSet={heroImg2} className={styles.hero__bgimageRight} />
                    </div>
                </div>
            </div>

            <div className={styles.collections}>
                <h3 className={styles.collections__title}>Our collections</h3>
                <Grid container spacing={2}>
                    {collections.map((el) => (
                        <CollectionCard data={el} key={el.image} />
                    ))}
                </Grid>
            </div>
            <Footer />
        </div>
    );
};

export default FractalDAO;
