import Header from 'components/Header';
import styles from './hero.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import WhitelistModal from 'components/WhitelistModal';
import { faTwitter, faTelegram } from '@fortawesome/free-brands-svg-icons';
import WhitelistInfoModal from 'components/WhitelistInfoModal';
import { useNavigate } from 'react-router';
import routes from 'constants/routes';
import arrowDownIcon from 'assets/images/arrow-down.png';
import HeroMarquee from 'components/HeroMarquee';
import { Tooltip } from '@mui/material';

const Hero = ({ modalType }) => {
    const [whitelistModalOpen, setWhitelistModalOpen] = useState(modalType ? true : false);
    const [whitelistInfoOpen, setWhitelistInfoOpen] = useState(false);
    const navigate = useNavigate();
    // const handleModal = (e) => {
    //     e.preventDefault();
    //     setWhitelistModalOpen(true);
    // };

    return (
        <div className={styles.hero}>
            <div className={styles.brightenBox}></div>
            <div className={styles.header__container}>
                <Header />
            </div>

            {/* <div className={styles.grid__bg}></div> */}
            <HeroMarquee />
            <div className={styles.hero__container}>
                <div className={styles.hero__ctx}>
                    <h3 className={styles.hero__title}>
                        Majestic <br /> Mushroom
                    </h3>
                    <h5 className={styles.hero__subtitle}>
                        A community to advance psychedelic art on Etheruem(420) and Tezos(4200)
                        ecosystem
                    </h5>
                    {/* <p className={styles.hero__info}>
                        Fractal Fungi is a digital collective of 4200 unique mushrooms, created
                        using mathematically generated fractals, coalesced into hand-drawn mushroom
                        skeletons. Each Fractal Fungi art generates 4.20 $ALCHMY tokens daily for
                        4.2 years which can be used to evolve & mint Fused Fungi NFTs. Fused NFTs
                        play a crucial part in governing the treasury & building a psychedelic art
                        community on Tezos.
                    </p> */}
                    {/* <button className={styles.whitelistBtn} onClick={handleModal}>
                        <span>AllowList</span>
                    </button> */}
                    <div className={styles.hero__btn}>
                        <Tooltip title={'Coming Soon'} sx={{ cursor: 'pointer' }}>
                            <button
                                // onClick={() => navigate(routes.MINT_PAGE)}
                                className={styles.whitelistBtn}
                            >
                                mint Now
                            </button>
                        </Tooltip>
                        <a
                            href="https://docs.fractalfungi.com/"
                            target={'_blank'}
                            rel="noreferrer"
                            className={styles.whitepaperBtn}
                        >
                            <span>WHITEPAPER</span>
                        </a>
                    </div>
                    <div className={styles.socialIcons}>
                        <a
                            href="https://twitter.com/FractalFungi"
                            target={'_blank'}
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon color="#1DA1F2" icon={faTwitter} />
                        </a>
                        <a href="https://t.me/fractalfungi" target={'_blank'} rel="noreferrer">
                            <FontAwesomeIcon color="#1DA1F2" icon={faTelegram} />
                        </a>
                    </div>

                    <div className={styles.arrowDown}>
                        <img srcSet={arrowDownIcon} />
                    </div>
                </div>
            </div>
            <WhitelistModal
                open={whitelistModalOpen}
                setOpen={setWhitelistModalOpen}
                modalType={modalType}
            />
            <WhitelistInfoModal open={whitelistInfoOpen} setOpen={setWhitelistInfoOpen} />
        </div>
    );
};

export default Hero;
