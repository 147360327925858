import React, { useState } from 'react';
import styles from './homeGallery.module.scss';
import selectedMush from 'assets/images/selectedGallery1.png';
import Slider from 'react-slick';
import { galleryMushroom } from 'constants/homepageGalleryMushrooms';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// const nextArrowStyle = {
//     position: 'absolute',
//     top: '95%',
//     bottom: '0px',
//     left: '40%',
//     background: 'none',

// }

// const prevArrowStyle = {
//     position: 'absolute',
//     top: '10px',
//     left: '40%',
//     background: 'none',
// }

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} ${styles.nextArrow}`}
            style={{ ...style, display: 'block' }}
            onClick={onClick}
        >
            <ArrowForwardIosIcon />
        </div>
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} ${styles.prevArrow}`}
            style={{ ...style, display: 'block' }}
            onClick={onClick}
        >
            <ArrowBackwardIosIcon />
        </div>
    );
}

const HomepageGallery = () => {
    const [selected, setSelected] = useState(galleryMushroom[0]);

    const sliderSettings = {
        className: styles.customSlider__slider,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        vertical: true,
        centerMode: true,
        focusOnSelect: true,
        verticalSwiping: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        beforeChange: function (currentSlide, nextSlide) {
            console.log('before change', currentSlide, nextSlide);
        },
        afterChange: function (currentSlide) {
            console.log('after change', currentSlide);
            setSelected(galleryMushroom[currentSlide]);
        },
        responsive: [
            {
                breakpoint: 762,
                settings: {
                    vertical: false,
                    verticalSwiping: false,
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    vertical: false,
                    verticalSwiping: false,
                },
            },
        ],
    };
    return (
        <div className={styles.Gallery}>
            <div className={styles.Gallery__Container}>
                <h3 className={styles.Gallery__Title}>Sample mushrooms</h3>
                <p className={styles.Gallery__TextInfo}>
                    Here are some stunning samples of our mushroom-inspired Fractal Fungi NFTs that
                    showcase the beauty and complexity of nature in mesmerizing fractal patterns,
                    inviting you to explore the infinite possibilities of this psychedelic art form.
                </p>
                <div className={styles.Gallery__Carousel}>
                    <div className={styles.Selected__Mushroom}>
                        <img src={selected.img} alt="" />
                        <span>{selected.title}</span>
                    </div>
                    <div className={styles.Vertical__Slider}>
                        <Slider {...sliderSettings}>
                            {galleryMushroom.map((item, idx) => (
                                <div key={idx} className={styles.Item__Slide}>
                                    <img src={item.img} alt="" />
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomepageGallery;
