import styles from './storymodals.module.scss';
import research1 from 'assets/images/research1.png';
import research2 from 'assets/images/research2.png';
import research3 from 'assets/images/research3.png';

import stitch1 from 'assets/images/stitch1.png';
import stitch2 from 'assets/images/stitch2.png';
import stitch3 from 'assets/images/stitch3.png';

import creation1 from 'assets/images/creation1.png';
import generation1 from 'assets/images/generation1.png';
import bacground1 from 'assets/images/background1.png';
import { Fade } from 'react-awesome-reveal';

const Creation = () => {
    return (
        <div className={styles.creationContent}>
            <h2 className={styles.ctx__title}>Creation</h2>

            <div className={styles.mainBody}>
                <Fade direction="up" triggerOnce={true} cascade damping={1e-1}>
                    <p>
                        The Fractal Fungi NFT collection has 4200 mushrooms. These mushrooms were
                        created in four parts.
                    </p>
                    <h3>Research and identification of magic mushrooms.</h3>

                    <p>
                        Initially we researched mushroom species that have magical properties. We
                        refer as magical properties to those which contain tryptamine alkaloids such
                        as psilocybin and psilocin. A total of 12 magical mushroom genera was
                        determined to include in our collection. The genera include:{' '}
                        <span className={styles.highlight}>
                            Psilocybe , Gymnopilus, Panaeolus, Copelandia, Hypholoma, Pluteus,
                            Inocybe, Conocybe, Agrocybe, Galerina, Mycena and Amanita Muscaria.{' '}
                        </span>{' '}
                        Each genera of mushroom come in 3 varieties: single, pair, and group.
                    </p>
                    <div className={styles.imageContainer}>
                        <div>
                            <img src={research1} alt="" style={{ width: '568px' }} />
                        </div>

                        <div className={styles.imageContainer2}>
                            <img src={research2} alt="" style={{ width: '326px' }} />
                            <img src={research3} alt="" style={{ width: '326px' }} />
                        </div>
                    </div>
                </Fade>

                <Fade direction="up" triggerOnce={true}>
                    <section>
                        <h3>Creation of mushroom skeletons</h3>
                        <p>
                            The outline of each mushroom art is referred as “skeletons”. These
                            skeletons were meticulously hand drawn based on how they occur in
                            nature. In this way a total of 72 unique sketches were fashioned.
                        </p>
                        <div className={styles.imageContainer}>
                            <img src={creation1} alt="" style={{ width: '100%' }} />
                        </div>
                    </section>
                </Fade>
                <Fade direction="up" triggerOnce={true}>
                    <section>
                        <h3>Generation of fractal from Mandelbub3D</h3>
                        <p>
                            Our team explored the infinite world of fractals in Mandelbub3D through
                            fractal transform formulas and algorithms. Various visual effects (fog,
                            shadows, reflections, lighting, color characteristics etc) were
                            experimented to render the best quality fractals.
                        </p>
                        <div className={styles.imageContainer}>
                            <img src={generation1} alt="" style={{ width: '100%' }} />
                        </div>
                    </section>
                </Fade>
                <Fade direction="up" triggerOnce={true}>
                    <section>
                        <h3>Stitching and final selection of the mushrooms</h3>
                        <p>
                            The hand drawn mushroom skeletons were then algorithmically stitched
                            with the fractals generated. The fractals that best expresses the
                            magical properties of the mushrooms (both from aesthetical and
                            mysterious sense) was hand picked by the team.
                        </p>
                        <div className={styles.imageContainer}>
                            <div>
                                <img src={stitch1} alt="" />
                            </div>
                            <div>
                                <img src={stitch2} alt="" />
                            </div>
                            <div>
                                <img src={stitch3} alt="" />
                            </div>
                        </div>
                    </section>
                </Fade>
                <Fade direction="up" triggerOnce={true}>
                    <section>
                        <h3>Background</h3>
                        <p>
                            The mushroom backgrounds are created using natural elements that
                            represents infiniteness in the universe.
                            <br></br>
                            <br></br>
                            For example: We have used Vortex, Spiral, Shooting Stars, Nebula all of
                            which are the components of the sprawling system of Galaxy. We have also
                            used Mycelium and Real Roots to create the background. Mycelium or roots
                            of mushroom are said to be the infinite neurological network of the
                            earth which stays in constant molecular communication with the
                            environment.
                        </p>
                        <div className={styles.imageContainer}>
                            <img src={bacground1} alt="" style={{ width: '100%' }} />
                        </div>
                    </section>
                </Fade>
            </div>
        </div>
    );
};

export default Creation;
