import { collection, getDocs, query, where, addDoc, Timestamp } from 'firebase/firestore';
import { firebaseDB } from '../config/firebase';
import { firebaseDBNames } from '../constants/firebase';

const whiteListUserRef = collection(firebaseDB, firebaseDBNames.whitelistUsers);

export const addWhiteListUser = async (data) => {
    try {
        const docRef = await addDoc(whiteListUserRef, {
            walletAddress: data.walletAddress,
            walletChainType: data.chainType,
            whitelistType: data.whitelistType,
            tezosAddress: data.tezosAddress,
            nftIds: data.nftIds,
            createdAt: Timestamp.now(),
        });
        return docRef;
    } catch (e) {
        console.error('Error adding document: ', e);
        throw e;
    }
};

export const getWhiteListUserByWallet = async (walletAddress, whitelistType) => {
    const q = query(
        whiteListUserRef,
        where('walletAddress', '==', walletAddress),
        where('whitelistType', '==', whitelistType),
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot;
};

export const getAllUsers = async () => {
    const q = query(whiteListUserRef);
    const querySnapshot = await getDocs(q);
    let arr = [];
    querySnapshot.forEach((el) => {
        const data = el.data();
        arr = [...arr, data];
    });
    return arr;
};
