import Marquee from 'react-fast-marquee';
import styles from './heroMaequee.module.scss';
import mush1 from 'assets/images/hero_marquee1.png';
import mush2 from 'assets/images/hero_marquee2.png';
import mush3 from 'assets/images/hero_marquee3.png';
import mush4 from 'assets/images/hero_marquee4.png';
import mush5 from 'assets/images/hero_marquee5.png';
import mush6 from 'assets/images/hero_marquee6.png';
import mush7 from 'assets/images/hero_marquee7.png';
import mush8 from 'assets/images/hero_marquee8.png';
import mush9 from 'assets/images/hero_marquee9.png';
import mush10 from 'assets/images/hero_marquee10.png';
import mush11 from 'assets/images/hero_marquee11.png';
import mush12 from 'assets/images/hero_marquee12.png';
import mush13 from 'assets/images/hero_marquee13.png';
import mush14 from 'assets/images/hero_marquee14.png';
import mush15 from 'assets/images/hero_marquee15.png';
import mush16 from 'assets/images/hero_marquee16.png';
import mush17 from 'assets/images/hero_marquee17.png';
import mush18 from 'assets/images/hero_marquee18.png';
import mush19 from 'assets/images/hero_marquee19.png';
import mush20 from 'assets/images/hero_marquee20.png';
import mush21 from 'assets/images/hero_marquee21.png';

const images = [
    {
        img1: mush1,
        img2: mush2,
        img3: mush3,
    },
    {
        img1: mush4,
        img2: mush5,
        img3: mush6,
    },
    {
        img1: mush7,
        img2: mush8,
        img3: mush9,
    },
    {
        img1: mush10,
        img2: mush11,
        img3: mush12,
    },
    {
        img1: mush13,
        img2: mush14,
        img3: mush15,
    },
    {
        img1: mush16,
        img2: mush17,
        img3: mush18,
    },
    {
        img1: mush19,
        img2: mush20,
        img3: mush21,
    },
    {
        img1: mush13,
        img2: mush14,
        img3: mush15,
    },
]

const HeroMarquee = () => {
  return (
    <div className={styles.marquee__box}>
        <Marquee gradient={false} autofill={true} speed={50} direction={'left'} pauseOnHover={false}>
            <div className={styles.marquee}>
                {images.map((item, idx) => (
                    <div className={styles.grid__col} key={idx}>
                        <div className={styles.grid__item}>
                            <img src={item.img1} alt="" />
                        </div>
                        <div className={styles.grid__item}>
                            <img src={item.img2} alt="" />
                        </div>
                        <div className={styles.grid__item}>
                            <img src={item.img3} alt="" />
                        </div>
                    </div>
                ))}
            </div>
        </Marquee>
    </div>
  )
}

export default HeroMarquee