import { Grid } from '@mui/material';
import styles from './collection.module.scss';

const CollectionCard = ({ data }) => {
    return (
        <Grid key={data.image} item sm={6} md={4} xs={12} className={styles.collectionCard}>
            <div className={styles.container}>
                <a
                    href={data.url}
                    target={'_blank'}
                    rel="noreferrer"
                    style={{ textDecoration: 'none', border: 'none' }}
                >
                    <div className={styles.img__container}>
                        <img src={data.image} />
                    </div>
                    <div className={styles.card__info}>
                        <p className={styles.title}>{data.title}</p>
                        <p className={styles.creator}>Artist: {data.creator}</p>
                    </div>
                </a>
            </div>
        </Grid>
    );
};

export default CollectionCard;
