import styles from './mushroomInfo.module.scss';
import { useContext } from 'react';
import { StoreContext } from 'store/useStore';
import MushroomCarousel from 'components/MushroomCarousel';

const MushroomInfo = () => {
    const { state } = useContext(StoreContext);
    const { tabContent } = state;
    return (
        <div className={styles.container}>
            <h3 className={styles.title}>
                {/* <span>{tabContent?.id}.</span> */}
                {tabContent?.title}
            </h3>
            <p className={styles.text}>{tabContent?.text}</p>

            <MushroomCarousel />
        </div>
    );
};

export default MushroomInfo;
