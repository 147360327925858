import AlchemyToken from 'components/AlchemyToken';
import Footer from 'components/Footer';
import Hero from 'components/Hero';
import MergedNFT from 'components/MergedNFT';
import Story from 'components/Story';
import HowItWorks from 'components/HowItWorks';
import styles from './style.module.scss';
import GenerateMushroom from 'components/GenerateMushroom';
import FusedFungi from 'components/FusedFungi';
import HomepageGallery from 'components/HomepageGallery';

const Homepage = ({ modalType }) => {
    return (
        <div className={styles.homeBg}>
            <Hero modalType={modalType} />
            <GenerateMushroom />
            <HomepageGallery />
            <FusedFungi />
            {/* <Story /> */}
            {/* <HowItWorks />
            <MergedNFT /> */}
            <AlchemyToken />
            <Footer />
        </div>
    );
};

export default Homepage;
