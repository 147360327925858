import Header from 'components/Header';
import routes from 'constants/routes';
import { Link } from 'react-router-dom';
import styles from './notFound.module.scss';
import mushOutlineLeft from 'assets/images/mushOutline1.png';
import mushOutlineRight from 'assets/images/mushOutline2.png';
import Footer from 'components/Footer';

const NotFoundPage = () => {
    return (
        <div className={styles.notFoundPage}>
            <div className={styles.notFoundPage__hero}>
                <div className={styles.brightenBox}></div>
                <div className={styles.notFoundPage__headerBg}>
                    <div className={styles.container}>
                        <Header />
                        <div className={styles.hero}>
                            <div className={styles.hero__ctx}>
                                <h3 className={styles.hero__title}>
                                    Go home, you must be tripping!
                                </h3>
                                <p className={styles.hero__text}>
                                    We couldn’t find the page you’re looking for
                                </p>
                                <Link to={routes.HOMEPAGE}>
                                    <button className={styles.backBtn}>Back to Home</button>
                                </Link>
                            </div>
                            <div className={styles.mushBg}>
                                <img src={mushOutlineLeft} alt="" />
                                <img src={mushOutlineRight} alt="" />
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFoundPage;
