import styles from './footer.module.scss';
import logo from 'assets/images/Logo.png';
// import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faTelegram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <div className={styles.footer}>
            <div className={styles.footer__container}>
                {/* <div className={styles.footer__ctx}>
                    <div className={styles.footer__contact}>
                        <div className={styles.footer__logo}>
                            <img src={logo} alt="Fractal Fungi" />
                        </div>
                        <div className={styles.socialIcons}>
                            <h4>Join our community:</h4>
                            <div className={styles.icons}>
                                <a
                                    href="https://twitter.com/fractalfungi"
                                    target={'_blank'}
                                    rel="noreferrer"
                                >
                                    <FontAwesomeIcon color="#1DA1F2" icon={faTwitter} />
                                </a>
                                <a
                                    href="https://t.me/fractalfungi"
                                    target={'_blank'}
                                    rel="noreferrer"
                                >
                                    <FontAwesomeIcon color="#1DA1F2" icon={faTelegram} />
                                </a>
                            </div>
                        </div>
                        <h5 className={styles.footer__email}>connect@fractalfungi.art</h5>
                    </div>
                    <div className={styles.footer__link}>
                        <h5 className={styles.footer__linkTitle}>Quick Links</h5>
                        <ul>
                            <li className={styles.links}>
                                <Link>FAQs</Link>
                            </li>
                            <li className={styles.links}>
                                <Link>Terms of services</Link>
                            </li>
                            <li className={styles.links}>
                                <Link>Privacy policy</Link>
                            </li>
                        </ul>
                    </div>
                </div> */}
                <div className={styles.footer__ctx}>
                    <div className={styles.footer__logo}>
                        <img src={logo} alt="Fractal Fungi" />
                    </div>
                    <div className={styles.social__link}>
                        <a
                            href="https://twitter.com/fractalfungi"
                            target={'_blank'}
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon color="#fff" icon={faTwitter} />
                        </a>
                        <a href="https://t.me/fractalfungi" target={'_blank'} rel="noreferrer">
                            <FontAwesomeIcon color="#fff" icon={faTelegram} />
                        </a>
                    </div>
                </div>
                <div className={styles.footer__copyright}>
                    <p className={styles.footer__copyrightText}>
                        {' '}
                        • Copyright © 2023 • Fractal Fungi •{' '}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
