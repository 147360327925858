import { styled } from '@mui/material';

export const usePaginationStyles = styled(() => ({
    ul: {
        '& .MuiPaginationItem-root': {
            color: 'white',
            '&.Mui-selected': {
                // background: 'linear-gradient(319.37deg, #22118C 1.32%, #4489FB 102.15%);',
                color: 'white',
                border: '2px solid #301cb1',
                borderRadius: '12px',
                // borderImage: 'linear-gradient(319.37deg, #22118C 1.32%, #4489FB 102.15%) 1',
                // background: 'linear-gradient(white, white) padding-box, linear-gradient(to right, darkblue, darkorchid) border-box',
            },
        },
    },
}));
