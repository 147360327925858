export default {
    HOMEPAGE: '',
    SHROOMPEDIA: '/shroompedia',
    WHITELIST_GANGSTABET: '/gangstaverse',
    WHITELIST_WONDERGAME: '/wondergame',
    WHITELIST_KNIGHTS: '/knightsandpeasants',
    FRACTAL_RADIO: '/fractalFungiRadio',
    FRACTALDAO: '/dao',
    STORY_PAGE: '/story',
    // MINT_PAGE: '/mint',
};
