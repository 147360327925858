import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import blankMush from 'assets/images/mint/blankMush.svg';

const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    // region: process.env.REACT_APP_AWS_REGION,
});

export const listS3Objects = async (sessionID) => {
    const params = {
        Bucket: 'fractal-fungi-test',
        Prefix: `600X600-OUTPUT/${sessionID}`,
    };

    try {
        const data = await s3.listObjectsV2(params).promise();
        console.log(data.Contents);
        // The `Contents` array contains details of all objects in the folder
        // You can use the `Key` property to get the object's path and filename
        return data.Contents;
    } catch (err) {
        console.error(err);
    }
};

export async function getImageUrl(objectKey) {
    const bucketName = 'fractal-fungi-test';
    const url = await s3.getSignedUrlPromise('getObject', {
        Bucket: bucketName,
        Key: objectKey,
    });
    console.log({ bucketName, objectKey, url });
    return url;
}

const S3Image = ({ bucketName, objectKey, alt, className }) => {
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        async function getImageUrl() {
            const url = await s3.getSignedUrlPromise('getObject', {
                Bucket: bucketName,
                Key: objectKey,
            });
            console.log({ bucketName, objectKey, url });
            setImageUrl(url);
        }
        getImageUrl();
    }, [bucketName, objectKey]);

    if (!imageUrl) {
        return <img src={blankMush} />;
    }

    return <img src={imageUrl} alt={alt} className={className} />;
};

export default S3Image;
