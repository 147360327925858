import styles from './mintFinal.module.scss';
// import { response } from 'services/test';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useState } from 'react';
import Viewer from 'react-viewer';
import { productionMergeTwoMushroom, productionSingleMushroom } from 'services/api';
import { splitOptionTypes } from 'constants/types';
import { toast } from 'react-toastify';
import MintModal from 'components/MintModal';
import { response } from 'services/test';
import ReactDOM from 'react-dom';
import MintFinalImage from 'components/MintFinalImage';

export function openFullImageTab(src) {
    const newTab = window.open('');
    newTab.document.title = document.title;
    // Copy stylesheets from current window to new window
    const links = Array.from(document.getElementsByTagName('link'));
    links.forEach((link) => {
        const href = link.getAttribute('href');
        const rel = link.getAttribute('rel');
        if (href && rel === 'stylesheet') {
            const newLink = newTab.document.createElement('link');
            newLink.setAttribute('rel', 'stylesheet');
            newLink.setAttribute('type', 'text/css');
            newLink.setAttribute('href', href);
            newTab.document.head.appendChild(newLink);
        }
    });

    const container = newTab.document.createElement('div');
    newTab.document.body.appendChild(container);
    // Remove default margin from new window's body
    newTab.document.body.style.margin = '0';
    ReactDOM.render(<MintFinalImage src={src} />, container);
}

const MintFinal = ({
    mushroomGenera,
    splitType,
    mushroomType,
    backgroundType,
    backgroundColor,
    image,
    mushrooms,
    imageName,
    requestId,
    sessionId,
}) => {
    const mintDetails = {
        Genera: mushroomGenera,
        Split: splitType,
        Type: mushroomType,
        Background: backgroundType,
        'Price Per Mint': '',
        'Available balance': '',
    };

    const fetchFullImage = () => {
        setLoading(true);
        const background = `${backgroundType}-${backgroundColor}`;
        switch (splitType) {
            case splitOptionTypes.SINGLE:
                productionSingleMushroom({
                    image: mushrooms[0],
                    background: background,
                })
                    .then((response) => {
                        if (response?.error) {
                            toast.error(`Error while generating: ${response.error}`);
                            console.log('Error', response.error);
                            return;
                        }
                        setFullImage('data:image/jpeg;base64, ' + response);
                        console.log({ response });
                        openFullImageTab('data:image/jpeg;base64, ' + response);
                    })
                    .catch((reason) => {
                        console.log('Failed ', reason);
                        toast.error(`Error while generating: ${reason}`);
                    })
                    .finally(() => {
                        setLoading(false);
                    });

                // setTimeout(() => {
                //     setFullImage(response);
                //     console.log({ response });

                //     openFullImageTab('data:image/jpeg;base64, ' + response);
                //     setLoading(false);
                // }, 2000);

                break;

            case splitOptionTypes.TWO_SPLIT:
                console.log({
                    request_id: requestId,
                    session_id: sessionId,
                });

                productionMergeTwoMushroom({
                    image: imageName,
                    request_id: requestId,
                    session_id: sessionId,
                })
                    .then((response) => {
                        console.log({ response });
                    })
                    .catch((reason) => {
                        console.log('Failed ', reason);
                        toast.error(`Error while generating: ${reason}`);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
                break;
            case splitOptionTypes.FOUR_SPLIT:
                break;
            default:
                break;
        }
    };

    const [fullScreen, setFullScreen] = useState(false);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fullImage, setFullImage] = useState(null);

    const onClickHandler = () => {
        setOpen(true);
        if (!fullImage && !loading) {
            fetchFullImage();
        }
    };

    return (
        <div className={styles.mint}>
            {/* <div className={styles.brightenBox}></div> */}
            <div className={styles.mint__container}>
                <div className={styles.mint__ctx}>
                    <div className={styles.mint__left}>
                        <img src={image} />

                        <div onClick={onClickHandler}>
                            <VisibilityIcon />
                            <p>VIEW FULL IMAGE</p>
                        </div>
                    </div>
                    <div className={styles.mint__right}>
                        <h3 className={styles.mint__title}>Mint your Fractal fungi</h3>

                        <div className={styles.infoBoxContainer}>
                            <div className={styles.infoBox}>
                                {Object.keys(mintDetails).map((key) => {
                                    return (
                                        <div key={key} className={styles.container}>
                                            <p className={styles.key}>{key}</p>
                                            <p className={styles.value}>{mintDetails[key]}</p>
                                        </div>
                                    );
                                })}
                            </div>

                            <button onClick={onClickHandler} className={styles.mintBtn}>
                                Mint now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Viewer
                visible={fullScreen}
                onClose={() => setFullScreen(false)}
                images={[{ src: image, alt: '' }]}
                rotatable={false}
                scalable={false}
                changeable={false}
                drag={true}
                zoomSpeed={0.1}
                disableMouseZoom={true}
                minScale={1}
                maxScale={10}
            />
            {/* <div className={styles.brightenBoxBottom}></div> */}

            <MintModal open={open} setOpen={setOpen} fullImage={fullImage}></MintModal>
        </div>
    );
};

export default MintFinal;
