import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Snackbar, SnackbarContent } from '@mui/material';
import { faClose, faCircleCheck, faSquareXmark } from '@fortawesome/free-solid-svg-icons';
import styles from './notification.module.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import { notificationTypes } from 'constants/types';
library.add(faClose, faCircleCheck, faSquareXmark);

const Notification = ({ snackbarOpen, handleSnackbarClose, title, text, type }) => {
    return (
        <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <SnackbarContent
                aria-describedby="snackMessage"
                className={styles.snackMessage}
                message={
                    <span>
                        {type === notificationTypes.SUCCESS ? (
                            <FontAwesomeIcon
                                size="1x"
                                className={styles.success}
                                icon={faCircleCheck}
                            />
                        ) : (
                            <FontAwesomeIcon
                                size="1x"
                                className={styles.error}
                                icon={faSquareXmark}
                            />
                        )}

                        <div>
                            <p className={styles.notificationTitle}>{title}</p>

                            <p className={styles.notificationText}>{text}</p>
                        </div>

                        <IconButton
                            onClick={handleSnackbarClose}
                            // severity={notificationType}
                            // sx={{ width: '100%' }}
                        >
                            <FontAwesomeIcon color="#fff" size="xs" icon={faClose} />
                        </IconButton>
                    </span>
                }
            />
        </Snackbar>
    );
};

export default Notification;
