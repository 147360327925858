export const whiteListTypes = {
    GANGSTABET: 'gangstabet',
    WONDER_GAME: 'wondergame',
    STUDIO_MIRAI: 'studio mirai',
    KNIGHTS_PEASANTS: 'knightsandpeasants',
};

export const chainTypes = {
    ICON: 'icon',
    ETHEREUM: 'ethereum',
    POLYGON: 'polygon',
};

export const notificationTypes = {
    SUCCESS: 'success',
    ERROR: 'error',
};

export const storyModalTypes = {
    CONCEPT: 0,
    CREATION: 1,
    COMING: 2,
};

export const mushroomTypes = {
    V1: {
        SINGLE: 'V1 Single',
        PAIR: 'V1 Pair',
        GROUP: 'V1 Group',
    },
    V2: {
        SINGLE: 'V2 Single',
        PAIR: 'V2 Pair',
        GROUP: 'V2 Group',
    },
};

export const generaTypes = {
    AMANITA: 'Amanita',
    AGROCYBE: 'Agrocybe',
    CONOCYBE: 'Conocybe',
    COPELANDIA: 'Copelandia',
    GALERINA: 'Galerina',
    GYMNOPILUS: 'Gymnopilus',
    HYPHOLOMA: 'Hypholoma',
    INOCYBE: 'Inocybe',
    MYCENA: 'Mycena',
    PANAEOLUS: 'Panaeolus',
    PLUTEUS: 'Pluteus',
    PSILOCYBE: 'Psilocybe',
};

export const splitOptionTypes = {
    SINGLE: 'Single mushroom',
    TWO_SPLIT: '2-split mushroom',
    FOUR_SPLIT: '4-split mushroom',
};

const SOUNDCLOUD_URL1 =
    'https://api.soundcloud.com/playlists/1543059004%3Fsecret_token%3Ds-dmzyQt6SVUb&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true';
const SOUNDCLOUD_URL2 =
    'https://api.soundcloud.com/playlists/1543069003%3Fsecret_token%3Ds-nBlxapoXPVU&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true';

export const playlistTyes = {
    'Mom, I\'m with the Shaman': SOUNDCLOUD_URL1,
    'Full Power, Full Shakti': SOUNDCLOUD_URL2,
};
