import styles from './galleryItem.module.scss';

const GalleryItem = ({ data }) => {
    return (
        <div className={styles.gallery__card} style={{ boxShadow: `0 0 0 2px ${data.border}` }}>
            <div className={styles.card__galleryItem}>
                <figure className={styles.card__galleryMedia}>
                    <img className={styles.gallery__cardImg} src={data.image} alt="" />
                    <figcaption className={styles.card__galleryCaption}>
                        <span className={styles.card__galleryName}>{data.name}</span>
                        <span className={styles.card__galleryGener}>{data.gener}</span>
                    </figcaption>
                </figure>
            </div>
        </div>
    );
};

export default GalleryItem;
