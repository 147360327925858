import MushroomInfo from 'components/MushroomInfo';
import MusroomTab from 'components/MushroomTabs';
import styles from './knowMushroom.module.scss';

const KnowYourMushroom = () => {
    return (
        <div className={styles.knowMushroom}>
            <div className={styles.container}>
                <p className={styles.scroll}>Scroll &#8594;</p>
                <MusroomTab />
                <MushroomInfo />
            </div>
        </div>
    );
};

export default KnowYourMushroom;
