import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useReducer } from 'react';
import { StoreContext } from 'store/useStore';
import { initialState, reducer } from 'store/reducer';
import { Web3ReactProvider } from '@web3-react/core';
import Homepage from 'pages/Homepage';
import routes from 'constants/routes';
import Shroompedia from 'pages/Shroompedia';
import { whiteListTypes } from 'constants/types';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MetaMaskProvider } from 'helper/metamask';
import Web3 from 'web3';
import FractalDAO from 'pages/FractalDAOPage';
import StoryPage from 'pages/StoryPage';
import NotFoundPage from 'pages/NotFoundPage';
import FractalRadioPage from 'pages/FractalRadioPage';
import MintPage from 'pages/MintPage';

function getLibrary(provider) {
    return new Web3(provider);
}

const App = () => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <MetaMaskProvider>
                <StoreContext.Provider value={{ state, dispatch }}>
                    <Router>
                        <Routes>
                            <Route path={routes.HOMEPAGE} element={<Homepage />} />
                            <Route path={routes.FRACTAL_RADIO} element={<FractalRadioPage />} />
                            <Route
                                path={routes.WHITELIST_GANGSTABET}
                                element={<Homepage modalType={whiteListTypes.GANGSTABET} />}
                            />
                            <Route
                                path={routes.WHITELIST_WONDERGAME}
                                element={<Homepage modalType={whiteListTypes.WONDER_GAME} />}
                            />
                            <Route
                                path={routes.WHITELIST_KNIGHTS}
                                element={<Homepage modalType={whiteListTypes.KNIGHTS_PEASANTS} />}
                            />
                            <Route path={routes.SHROOMPEDIA} element={<Shroompedia />} />
                            <Route path={routes.FRACTALDAO} element={<FractalDAO />} />
                            <Route path={routes.STORY_PAGE} element={<StoryPage />} />
                            {/* <Route path={routes.MINT_PAGE} element={<MintPage />} /> */}
                            <Route path={'*'} element={<NotFoundPage />} />
                        </Routes>
                    </Router>
                    <ToastContainer theme={'dark'} pauseOnHover={false} />
                </StoreContext.Provider>
            </MetaMaskProvider>
        </Web3ReactProvider>
    );
};

export default App;
