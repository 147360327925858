import mushroom1 from 'assets/images/homepage_gallery/multi_spiral-orange@left1-right2_42_446_2_inocybe-geophylla_group.png';
import mushroom2 from 'assets/images/homepage_gallery/multi_spiral-violet@344@411@88@79@2_agrocybe-praecox_group.png';
import mushroom3 from 'assets/images/homepage_gallery/multi_spiral-violet@up1-down2_347_385_2_amanita-muscaria_group.png';
import mushroom4 from 'assets/images/homepage_gallery/nebula-brown@left1-right2_473_420_1_hypholoma-marginatum_pair.png';
import mushroom5 from 'assets/images/homepage_gallery/nebula-golden@left2-right1_295_183_2_gymnopilus-junonius_single.png';
import mushroom6 from 'assets/images/homepage_gallery/nebula-sky_blue@238@256@256@407@1_conocybe-filaris_group.png';
import mushroom7 from 'assets/images/homepage_gallery/real_root-pink@left1-right2_181_336_1_conocybe-filaris_group.png';
import mushroom8 from 'assets/images/homepage_gallery/real_root-pink@up2-down1_35_479_2_galerina-marginata_group.png';
import mushroom9 from 'assets/images/homepage_gallery/real_root-violet@left2-right1_168_416_2_copelandia-cyanescens_pair.png';
import mushroom10 from 'assets/images/homepage_gallery/shooting_star-silver@221@470@258@467@1_pluteus-cervinus_pair.png';
import mushroom11 from 'assets/images/homepage_gallery/shooting_star-violet@up1-down2_309_455_2_panaeolus-semiovatus_single.png';
import mushroom12 from 'assets/images/homepage_gallery/spiral-brown@left1-right2_383_501_1_psilocybe-subaeruginosa_pair.png';
import mushroom13 from 'assets/images/homepage_gallery/spiral-dark_blue@left2-right1_98_24_2_hypholoma-fasciculare_group.png';
import mushroom14 from 'assets/images/homepage_gallery/spiral-dark_green@up1-down2_130_208_1_mycena-galericulata_pair.png';
import mushroom15 from 'assets/images/homepage_gallery/spiral-red@331@291@470@247@1_galerina-marginata_single.png';
import mushroom16 from 'assets/images/homepage_gallery/spiral-yellow@93@266@64@284@1_gymnopilus-junonius_group.png';
import mushroom17 from 'assets/images/homepage_gallery/vortex-dark_blue@up1-down2_300_244_1_agrocybe-praecox_group.png';
import mushroom18 from 'assets/images/homepage_gallery/vortex-green@left2-right1_149_287_1_pluteus-cervinus_pair.png';
import mushroom19 from 'assets/images/homepage_gallery/vortex-green@left2-right1_394_248_2_inocybe-geophylla_group.png';
import mushroom20 from 'assets/images/homepage_gallery/vortex-pink@up1-down2_113_449_1_mycena-galericulata_pair.png';
import mushroom21 from 'assets/images/homepage_gallery/vortex-sky_blue@up1-down2_120_242_1_psilocybe-mexicana_single.png';
import mushroom22 from 'assets/images/homepage_gallery/vortex2-light_green@left1-right2_479_422_2_panaeolus-subbalteatus_group.png';
import mushroom23 from 'assets/images/homepage_gallery/vortex2-orange@402@131@108@214@1_copelandia-cyanescens_group.png';
import mushroom24 from 'assets/images/homepage_gallery/vortex2-orange@up2-down1_501_468_1_amanita-muscaria_single.png';

const getImageTitle = (imagePath) => {
    const start = imagePath.lastIndexOf('/') + 1;
    const end = imagePath.indexOf('@');
    const extractedString = imagePath.slice(start, end);
    const filteredString = extractedString.replace(/[-_]/g, ' ');
    const formattedString = filteredString
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    return formattedString;
};

export const galleryMushroom = [
    {
        img: mushroom1,
        title: getImageTitle(mushroom1),
    },
    {
        img: mushroom2,
        title: getImageTitle(mushroom2),
    },
    {
        img: mushroom3,
        title: getImageTitle(mushroom3),
    },
    {
        img: mushroom4,
        title: getImageTitle(mushroom4),
    },
    {
        img: mushroom5,
        title: getImageTitle(mushroom5),
    },
];
