import styles from './storymodals.module.scss';
import { Fade } from 'react-awesome-reveal';

const Coming = () => {
    return (
        <div className={styles.modal__ctx}>
            <div className={styles.ctx__container}>
                <Fade direction="up" triggerOnce cascade damping={1e-1}>
                    <h3 className={styles.ctx__title}>Coming</h3>
                    <p className={styles.ctx__text}>
                        The idea of Fractal Fungi was born out of fascination of mathematical art
                        and mushrooms. As the world adopted a new form of digital art and ownership
                        (NFTs), we saw this as a perfect opportunity to create a unique piece of art
                        in the form of fractals and fungi.
                    </p>

                    <p className={styles.ctx__text}>
                        Fractals because it represents a psychedelic form of art that can be
                        generated using mathematics and Fungi because of the mysterious and magical
                        properties it holds, like healing and regeneration of life. Both represent
                        infiniteness in its form and possibility. Our ultimate goal is to push the
                        boundaries of psychedelic art form, and we hope this inspires a generation
                        to advocate for the discovery and sustainability of both.
                    </p>

                    <p className={styles.ctx__text}>
                        This project will be launched in the Tezos blockchain. Fractal Fungi NFTs
                        will evolve and ascribe value in the hands of artists, curators and
                        collectors through FractalFungi DAO.
                    </p>

                    <p className={styles.ctx__text}>
                        Fractal Fungi believes in benevolence so we intend to make periodic
                        donations to organizations dedicated to the research of medicinal mushrooms
                        and psychedelic medicine.
                    </p>
                </Fade>
            </div>
        </div>
    );
};

export default Coming;
