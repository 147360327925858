import React, { useState } from 'react';
import styles from './fused.module.scss';
import Split21 from 'assets/images/2-split-1.png';
import Split22 from 'assets/images/2-split-2.png';
import Fused2 from 'assets/images/2-split-fused.png';

import Split41 from 'assets/images/4-split-1.png';
import Split42 from 'assets/images/4-split-2.png';
import Split43 from 'assets/images/4-split-3.png';
import Split44 from 'assets/images/4-split-4.png';
import Fused4 from 'assets/images/4-split-fused2.png';

const FusedFungi = () => {
    const [selected, setSelected] = useState(4);
    return (
        <div className={styles.FusedFungi}>
            <div className={styles.container}>
                <h3 className={styles.section__title}>Fused Fungi NFTs</h3>
                <p className={styles.section__text}>
                    The Fractal Fungi NFT collection comprises 12 mushroom genera, each available in
                    three distinct varieties: single, pair, and group. With the ability to merge
                    mushrooms of similar structures, the collection offers endless possibilities for
                    exploring the beauty of these mesmerizing fractal patterns. Don&apos;t miss the
                    chance to own a unique piece of psychedelic art.
                </p>

                <div className={styles.Fungi__SplitWrapper}>
                    <div className={styles.Fungi__splitTabs}>
                        <button
                            className={`${styles.Fungi__TabBtn} ${selected === 2 && styles.active}`}
                            onClick={() => setSelected(2)}
                        >
                            2-Split mushroom
                        </button>
                        <button
                            className={`${styles.Fungi__TabBtn} ${selected === 4 && styles.active}`}
                            onClick={() => setSelected(4)}
                        >
                            4-Split mushroom
                        </button>
                    </div>

                    {selected === 2 && (
                        <div className={styles.Fungi__SplitComponents}>
                            <div className={styles.Fungi__FusableLeft}>
                                <div className={styles.Fungi__FusableLeft__One}>
                                    <img src={Split21} alt="" />
                                </div>
                            </div>
                            <div className={styles.Fungi__FusableTop}>
                                <div className={styles.Fungi__FusableLeft__One}>
                                    <img src={Split21} alt="" />
                                </div>
                            </div>
                            <div className={styles.Fungi__FusedShroom}>
                                <img src={Fused2} alt="" />
                                <h5>Mycena Galericulata ~ Group</h5>
                            </div>
                            <div className={styles.Fungi__FusableRight}>
                                <div></div>
                                <div className={styles.Fungi__FusableRight__Four}>
                                    <img src={Split22} alt="" />
                                </div>
                            </div>
                            <div className={styles.Fungi__FusableBottom}>
                                <div></div>
                                <div className={styles.Fungi__FusableRight__Four}>
                                    <img src={Split22} alt="" />
                                </div>
                            </div>
                        </div>
                    )}

                    {selected === 4 && (
                        <div className={styles.Fungi__SplitComponents}>
                            <div className={styles.Fungi__FusableLeft}>
                                <div className={styles.Fungi__FusableLeft__One}>
                                    <img src={Split41} alt="" />
                                </div>
                                <div className={styles.Fungi__FusableLeft__Two}>
                                    <img src={Split42} alt="" />
                                </div>
                            </div>
                            <div className={styles.Fungi__FusableTop}>
                                <div className={styles.Fungi__FusableLeft__One}>
                                    <img src={Split41} alt="" />
                                </div>
                                <div className={styles.Fungi__FusableLeft__Two}>
                                    <img src={Split43} alt="" />
                                </div>
                            </div>
                            <div className={styles.Fungi__FusedShroom}>
                                <img src={Fused4} alt="" />
                                <h5>Conocybe Filaris ~ Group</h5>
                            </div>
                            <div className={styles.Fungi__FusableRight}>
                                <div className={styles.Fungi__FusableRight__Three}>
                                    <img src={Split43} alt="" />
                                </div>
                                <div className={styles.Fungi__FusableRight__Four}>
                                    <img src={Split44} alt="" />
                                </div>
                            </div>
                            <div className={styles.Fungi__FusableBottom}>
                                <div className={styles.Fungi__FusableRight__Three}>
                                    <img src={Split42} alt="" />
                                </div>
                                <div className={styles.Fungi__FusableRight__Four}>
                                    <img src={Split44} alt="" />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FusedFungi;
