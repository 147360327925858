import styles from './alchemyToken.module.scss';
import curveArrow from 'assets/images/curveArrow.png';
import gallery1 from 'assets/images/gallery1.jpg';
import gallery2 from 'assets/images/gallery2.jpg';

const AlchemyToken = () => {
    return (
        <div className={styles.token}>
            {/* <div className={styles.brightenBox}></div> */}
            <div className={styles.token__container}>
                {/* <div className={styles.token__ctx}>
                    <h3 className={styles.token__title}>ALCHMY Token</h3>
                    <p className={styles.token__text}>
                        ALCHMY is the native token in the Fractal Fungi ecosystem. It can be minted only via the Fractal Fungi NFTs. Each NFTs generate 4.20 tokens daily for 3 years. The tokens can then be used to set the name and description of the NFTs. 100% of the used tokens are burnt making it deflationary in nature.
                    </p>
                    <p className={styles.token__text}>
                        ALCHMY tokens are used to mutate two or four Fractal Fungi NFTs to mint a new Fused Fungi NFTs. The Fused Fungi NFTs can vote in the Fused Fungi NFTs to govern the treasury.
                    </p>
                    <a href="" className={styles.token__link}>
                        <span>Learn more</span>
                        <img src={arrow} alt="" />
                    </a>
                </div> */}

                {/* <div className={styles.token__ctx}>
                    <div className={styles.token__gridItem}>
                        <div className={styles.token__layout}></div>
                    </div>
                    <div className={styles.token__ctx}>
                        <h3 className={styles.token__title}>Fused Fungi NFTs</h3>
                        <p className={styles.token__text}>
                            To support the continued decentralization of the Fractal Fungi project,
                            we introduced Fractal Fungi DAO. All merged fractal fungi NFT holders
                            will participate in the DAO. Each holder can cast a vote to choose which
                            projects will be pursued.
                        </p>
                        <a href="" className={styles.token__link}>
                            <span>Learn more</span>
                            <img src={arrow} alt="" />
                        </a>
                    </div>
                </div> */}
                <div className={styles.token__ctx}>
                    {/* <div className={styles.token__gridItem}> */}
                    <h3 className={styles.token__title}>Fractal Launchpad</h3>
                    <p className={styles.token__text}>
                        The launchpad is an initiative to collaborate with artists and art curators
                        all around the globe to promote psychedelic-themed artwork. We welcome all
                        artists who are passionate about creating psychedelic-themed artwork to join
                        our platform and share their unique perspectives with a global audience.
                        Through our Launchpad initiative, we aim to provide a platform for artists
                        to showcase their work, connect with like-minded individuals, and gain
                        recognition for their art in the growing psychedelic community.
                    </p>

                    {/* <p className={styles.token__text}>
                        Artists can use the launchpad platform to mint psychedelic art which can be
                        purchased by Fractal Fungi or Fused Fungi NFTs holders. The holders will
                        have exclusive access to buy them. Artists will require 42 ALCHMY tokens to
                        launch their artwork in Fractal Launchpad.
                    </p> */}
                    <div className={styles.token__link}>
                        <img src={curveArrow} alt="" />
                        <span>&nbsp;&nbsp;Coming soon</span>
                        <img src={curveArrow} alt="" />
                    </div>
                    {/* </div> */}
                    {/* <div className={styles.token__gridItem}>
                        <div className={styles.token__layout}></div>
                    </div> */}
                </div>

                <div className={styles.merge__grid}>
                    <div className={styles.merge__galleryItem}>
                        <div className={styles.mushroomImg}>
                            <img src={gallery1} alt="" />
                        </div>
                    </div>
                    <div className={styles.merge__galleryItem}>
                        <div className={styles.mushroomImg}>
                            <img src={gallery2} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.brightenBoxBottom}></div>
        </div>
    );
};

export default AlchemyToken;
