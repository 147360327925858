import styles from './mushroomGenera.module.scss';
import { importFromPath } from 'helper/multiImport';
import { generaTypes } from 'constants/types';

const generaImages = importFromPath(
    require.context('../../../assets/images/mint/genera', false, /\.(png|jpe?g|svg)$/),
);

const Option = ({ title, description, image, selected, onSelect }) => {
    return (
        <div
            className={selected ? styles.option__selected : styles.option}
            onClick={() => onSelect(title)}
        >
            <div className={styles.optionContainer}>
                <img src={image} alt="" />
                <div>
                    <h3>{title}</h3>
                    <p>{description}</p>
                </div>
            </div>
        </div>
    );
};

const generaOptions = [
    {
        id: 1,
        title: generaTypes.AGROCYBE,
        description:
            'Vitae rhoncus nisi nulla et tristique tempus sagittis aliquet mauris. Pulvinar lobortis porta gravida viverra dolor tincidunt enim non.',
        image: generaImages[0],
    },
    {
        id: 2,
        title: generaTypes.AMANITA,
        description:
            'Vitae rhoncus nisi nulla et tristique tempus sagittis aliquet mauris. Pulvinar lobortis porta gravida viverra dolor tincidunt enim non.',
        image: generaImages[1],
    },
    {
        id: 3,
        title: generaTypes.CONOCYBE,
        description:
            'Vitae rhoncus nisi nulla et tristique tempus sagittis aliquet mauris. Pulvinar lobortis porta gravida viverra dolor tincidunt enim non.',
        image: generaImages[2],
    },
    {
        id: 4,
        title: generaTypes.COPELANDIA,
        description:
            'Vitae rhoncus nisi nulla et tristique tempus sagittis aliquet mauris. Pulvinar lobortis porta gravida viverra dolor tincidunt enim non.',
        image: generaImages[3],
    },
    {
        id: 5,
        title: generaTypes.GALERINA,
        description:
            'Vitae rhoncus nisi nulla et tristique tempus sagittis aliquet mauris. Pulvinar lobortis porta gravida viverra dolor tincidunt enim non.',
        image: generaImages[4],
    },
    {
        id: 6,
        title: generaTypes.GYMNOPILUS,
        description:
            'Vitae rhoncus nisi nulla et tristique tempus sagittis aliquet mauris. Pulvinar lobortis porta gravida viverra dolor tincidunt enim non.',
        image: generaImages[5],
    },
    {
        id: 7,
        title: generaTypes.HYPHOLOMA,
        description:
            'Vitae rhoncus nisi nulla et tristique tempus sagittis aliquet mauris. Pulvinar lobortis porta gravida viverra dolor tincidunt enim non.',
        image: generaImages[6],
    },
    {
        id: 8,
        title: generaTypes.INOCYBE,
        description:
            'Vitae rhoncus nisi nulla et tristique tempus sagittis aliquet mauris. Pulvinar lobortis porta gravida viverra dolor tincidunt enim non.',
        image: generaImages[7],
    },
    {
        id: 9,
        title: generaTypes.MYCENA,
        description:
            'Vitae rhoncus nisi nulla et tristique tempus sagittis aliquet mauris. Pulvinar lobortis porta gravida viverra dolor tincidunt enim non.',
        image: generaImages[8],
    },
    {
        id: 10,
        title: generaTypes.PANAEOLUS,
        description:
            'Vitae rhoncus nisi nulla et tristique tempus sagittis aliquet mauris. Pulvinar lobortis porta gravida viverra dolor tincidunt enim non.',
        image: generaImages[9],
    },
    {
        id: 11,
        title: generaTypes.PLUTEUS,
        description:
            'Vitae rhoncus nisi nulla et tristique tempus sagittis aliquet mauris. Pulvinar lobortis porta gravida viverra dolor tincidunt enim non.',
        image: generaImages[10],
    },
    {
        id: 12,
        title: generaTypes.PSILOCYBE,
        description:
            'Vitae rhoncus nisi nulla et tristique tempus sagittis aliquet mauris. Pulvinar lobortis porta gravida viverra dolor tincidunt enim non.',
        image: generaImages[11],
    },
];

export default function MushroomGenera({ mushroomGenera, updateFields }) {
    const onSelect = (value) => {
        updateFields({ mushroomGenera: value });
    };

    return (
        <div className={styles.container}>
            <h3 className={styles.title}>Select mushroom genera</h3>

            <div className={styles.optionsContainer}>
                {generaOptions.map((option, i) => (
                    <Option
                        title={option.title}
                        description={option.description}
                        image={option.image}
                        key={i}
                        selected={mushroomGenera === option.title}
                        onSelect={onSelect}
                    />
                ))}
            </div>
        </div>
    );
}
