import Header from 'components/Header';
import styles from './shroompedia.module.scss';
import heroImg from 'assets/images/hero-mushroom.png';
import KnowYourMushroom from 'components/KnowYourMushroom';
import titleOverlayImg from 'assets/images/title-bg.png';
import Footer from 'components/Footer';

const Shroompedia = () => {
    return (
        <div className={styles.shroompedia}>
            <div className={styles.shroom__hero}>
                <div className={styles.shroom__headerBg}>
                    <div className={styles.container}>
                        <Header />
                        <div className={styles.hero}>
                            <div className={styles.hero__ctx}>
                                <div className={styles.hero__img}>
                                    <img src={heroImg} alt="" />
                                </div>
                                <h3 className={styles.hero__title}>Shroompedia</h3>
                                <p className={styles.hero__text}>
                                    Shroompedia gives you stylistic descriptions of the 12 genera of
                                    mushrooms. <br></br> Learn more about the facets of each genus
                                    here and identify your type.
                                </p>
                                <img src={titleOverlayImg} className={styles.titleOverlay} alt="" />
                            </div>
                        </div>
                        <div className={styles.mouse}>
                            <div className={styles.mouse__icon}>
                                <span></span>
                            </div>
                            <span className={styles.mouse__text}>Scroll down</span>
                        </div>
                    </div>
                </div>
            </div>
            <KnowYourMushroom />
            <Footer />
        </div>
    );
};

export default Shroompedia;
