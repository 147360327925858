import { useContext } from 'react';
import { StoreContext } from 'store/useStore';
import actions from 'store/action';
import styles from './singleMushroom.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const SingleMushroom = ({ item }) => {
    const { state, dispatch } = useContext(StoreContext);
    const { selectedTab } = state;

    const { hash, key } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (hash) {
            const target = hash.substring(1);

            if (target === item.title.toLowerCase()) {
                dispatch({
                    type: actions.SET_TABCONTENT,
                    payload: {
                        tabContent: item,
                        selectedTab: item.id,
                        mushroomGallery: item.mushroomGallery,
                    },
                });
            }
        }
    }, [key, hash]);

    const handleTabClick = () => {
        dispatch({
            type: actions.SET_TABCONTENT,
            payload: {
                tabContent: item,
                selectedTab: item.id,
                mushroomGallery: item.mushroomGallery,
            },
        });
        navigate(`/shroompedia#${item.title.toLowerCase()}`);
    };

    return (
        <div
            className={styles.grid__item}
            data-selected={selectedTab == item.id ? true : false}
            onClick={handleTabClick}
        >
            <div className={styles.mushroom__item}>
                <img src={item.image} alt="" />
            </div>
            <div className={styles.mushroom__title}>
                <span>{item.title}</span>
            </div>
        </div>
    );
};

export default SingleMushroom;
