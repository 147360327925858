import { Circle, Close } from '@mui/icons-material';
import { Button, Fade, IconButton, Modal } from '@mui/material';
import { Box } from '@mui/system';
import { importFromPath, importFromPathWithName } from 'helper/multiImport';
import { useState } from 'react';
import styles from './backgroundSelect.module.scss';
const images = importFromPath(
    require.context('../../../assets/images/mint/background', false, /\.(png|jpe?g|svg)$/),
);

const Option = ({ backgroundType, selected, onSelect, index }) => {
    const images = backgroundType.images;
    // const image = images[Math.floor(Math.random() * images.length)].src;
    const color = backgroundType.defaultImageColor;
    const image = images.find((el) => el.name.includes(color))?.src;
    return (
        <div
            className={selected ? styles.option__selected : styles.option}
            onClick={() => onSelect(index)}
        >
            <div className={styles.optionContainer}>
                {/* <img src={backgroundType.defaultImage} alt="" /> */}
                <img src={image} alt="" />

                <div>
                    <h3>{backgroundType.displayTitle}</h3>
                    <p>{backgroundType.description}</p>
                </div>
            </div>
        </div>
    );
};

const colorOptions = [
    { name: 'red', color: '#D5111C', displayName: 'Red' },
    { name: 'yellow', color: '#ECBF1B', displayName: 'Yellow' },
    { name: 'golden', color: '#C8B018', displayName: 'Mustard' },
    { name: 'light_green', color: '#74E17C', displayName: 'Light Green' },
    { name: 'dark_green', color: '#1AA44C', displayName: 'Dark Green' },
    { name: 'sky_blue', color: '#1FBFEA', displayName: 'Light Blue' },
    { name: 'dark_blue', color: '#2E5DDE', displayName: 'Dark Blue' },

    { name: 'violet', color: '#6D34A6', displayName: 'Violet' },

    { name: 'pink', color: '#ED77CD', displayName: 'Pink' },
    { name: 'brown', color: '#8B5823', displayName: 'Brown' },

    { name: 'orange', color: '#EA822C', displayName: 'Orange' },

    { name: 'silver', color: '#CDC7C7', displayName: 'Grey' },
];

const ColorOption = ({ colorType, isSelected, handleClick }) => {
    return (
        <div className={styles.colorContainer}>
            <Circle
                className={isSelected ? styles.circleSelected : styles.circle}
                style={{ color: colorType.color }}
                onClick={handleClick}
            />
            <p>{colorType.displayName}</p>
        </div>
    );
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // minWidth: 500,
    // width: 730,
    maxHeight: 708,
    bgcolor: 'background.paper',
    background: 'linear-gradient(108.79deg, #100837 0%, #0A0810 100%)',
    borderRadius: '20px',
    p: 4,
    overflowY: 'scroll',

    '&::-webkit-scrollbar': {
        display: 'none',
    },
};

const typeOptions = [
    {
        title: 'mycelium',
        displayTitle: 'Mycelium',
        images: importFromPathWithName(
            require.context(
                '../../../assets/images/all_background/mycelium',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
        defaultImage: images[0],
        defaultImageColor: 'dark_green',

        description:
            'Sit et libero eu porta. Tellus volutpat senectus arcu pellentesque erat dolor elementum. Pretium accumsan id nulla congue aliquet ut turpis. Viverra odio morbi condimentum quisque viverra. Amet ultrices ornare volutpat id id metus quis.',
    },
    {
        title: 'spiral',
        displayTitle: 'Spiral',
        images: importFromPathWithName(
            require.context(
                '../../../assets/images/all_background/spiral',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
        defaultImage: images[1],
        defaultImageColor: 'dark_blue',
        description:
            'Sit et libero eu porta. Tellus volutpat senectus arcu pellentesque erat dolor elementum. Pretium accumsan id nulla congue aliquet ut turpis. Viverra odio morbi condimentum quisque viverra. Amet ultrices ornare volutpat id id metus quis.',
    },
    {
        title: 'multi_spiral',
        displayTitle: 'Multi spiral',
        images: importFromPathWithName(
            require.context(
                '../../../assets/images/all_background/multi_spiral',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
        defaultImage: images[2],
        defaultImageColor: 'yellow',
        description:
            'Sit et libero eu porta. Tellus volutpat senectus arcu pellentesque erat dolor elementum. Pretium accumsan id nulla congue aliquet ut turpis. Viverra odio morbi condimentum quisque viverra. Amet ultrices ornare volutpat id id metus quis.',
    },
    {
        title: 'vortex',
        displayTitle: 'Vortex',
        images: importFromPathWithName(
            require.context(
                '../../../assets/images/all_background/vortex',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
        defaultImage: images[3],
        defaultImageColor: 'red',
        description:
            'Sit et libero eu porta. Tellus volutpat senectus arcu pellentesque erat dolor elementum. Pretium accumsan id nulla congue aliquet ut turpis. Viverra odio morbi condimentum quisque viverra. Amet ultrices ornare volutpat id id metus quis.',
    },
    {
        title: 'real_root',
        displayTitle: 'Real root',
        defaultImage: images[4],
        images: importFromPathWithName(
            require.context(
                '../../../assets/images/all_background/real_root',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
        defaultImageColor: 'light_green',
        description:
            'Sit et libero eu porta. Tellus volutpat senectus arcu pellentesque erat dolor elementum. Pretium accumsan id nulla congue aliquet ut turpis. Viverra odio morbi condimentum quisque viverra. Amet ultrices ornare volutpat id id metus quis.',
    },
    {
        title: 'nebula',
        displayTitle: 'Nebula',
        defaultImage: images[5],
        defaultImageColor: 'orange',
        images: importFromPathWithName(
            require.context(
                '../../../assets/images/all_background/nebula',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
        description:
            'Sit et libero eu porta. Tellus volutpat senectus arcu pellentesque erat dolor elementum. Pretium accumsan id nulla congue aliquet ut turpis. Viverra odio morbi condimentum quisque viverra. Amet ultrices ornare volutpat id id metus quis.',
    },
    {
        title: 'vortex2',
        displayTitle: 'Vortex 2',
        defaultImage: images[6],
        defaultImageColor: 'red',
        images: importFromPathWithName(
            require.context(
                '../../../assets/images/all_background/vortex2',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
        description:
            'Sit et libero eu porta. Tellus volutpat senectus arcu pellentesque erat dolor elementum. Pretium accumsan id nulla congue aliquet ut turpis. Viverra odio morbi condimentum quisque viverra. Amet ultrices ornare volutpat id id metus quis.',
    },

    {
        title: 'shooting_star',
        displayTitle: 'Shooting star',
        defaultImage: images[7],
        defaultImageColor: 'violet',
        images: importFromPathWithName(
            require.context(
                '../../../assets/images/all_background/shooting_star',
                false,
                /\.(png|jpe?g|svg)$/,
            ),
        ),
        description:
            'Sit et libero eu porta. Tellus volutpat senectus arcu pellentesque erat dolor elementum. Pretium accumsan id nulla congue aliquet ut turpis. Viverra odio morbi condimentum quisque viverra. Amet ultrices ornare volutpat id id metus quis.',
    },
];

export default function BackgroundSelect({ backgroundType, backgroundColor, updateFields }) {
    const onSelect = (value) => {
        setOpen(true);
        setSelected(value);
    };

    const next = (value) => {
        console.log({ value });
        // updateFields({ backgroundType: `${typeOptions[selected].title}-${color}` });
        updateFields({ backgroundType: typeOptions[selected].title }, false);
        updateFields({ backgroundColor: color });
    };

    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const [selected, setSelected] = useState('');

    const [color, setColor] = useState(backgroundColor);

    const findSelectedImage = () => {
        const selectedOption = typeOptions[selected];
        const found = selectedOption?.images?.find((img) => img.name.includes(color));

        if (found) return found.src;
        return selectedOption?.images[0].src;
    };

    return (
        <div className={styles.container}>
            <h3 className={styles.title}>Select background</h3>
            <div className={styles.optionsContainer}>
                {typeOptions.map((option, i) => (
                    <Option
                        backgroundType={option}
                        key={i}
                        index={i}
                        selected={backgroundType === option.title}
                        onSelect={onSelect}
                    />
                ))}
            </div>
            <Modal open={open} onClose={handleClose} style={{ backdropFilter: 'blur(3px)' }}>
                <Fade in={open}>
                    <Box sx={style} className={styles.modalMain}>
                        <div className={styles.container1}>
                            <p>Select color</p>
                            <IconButton onClick={handleClose} style={{ color: 'white' }}>
                                <Close />
                            </IconButton>
                        </div>

                        <div className={styles.container2}>
                            <img src={findSelectedImage()} />
                            <div style={{ flex: 1, margin: '10px' }}>
                                {/* <h3>{typeOptions[selected]?.title}</h3>
                                <p>{typeOptions[selected]?.description}</p> */}
                                <div className={styles.circleGrid}>
                                    {colorOptions.map((el) => (
                                        <ColorOption
                                            key={el.name}
                                            colorType={el}
                                            isSelected={color === el.name}
                                            handleClick={() => setColor(el.name)}
                                        ></ColorOption>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <button
                            className={styles.continueBtn}
                            onClick={() => next(typeOptions[selected]?.title)}
                        >
                            Continue
                        </button>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
